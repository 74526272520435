import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Vendor from "../pages/Vendor";
import Users from "../pages/user/Users";
import UserDetails from "../pages/user/UserDetails";
import UserReport from "../pages/Report/UserReport";
import BookingReport from "../pages/Report/BookingReport";
import ParkingReport from "../pages/Report/ParkingReport";

import ParkingList from "../pages/parkingManagement/ParkingList";
import ParkingBookings from "../pages/parkingManagement/ParkingBookings";
import ParkingFacilities from "../pages/parkingManagement/ParkingFacilities";
import Brands from "../pages/parkingManagement/vehicleSettings/Brands";
import Modals from "../pages/parkingManagement/vehicleSettings/Modals";
import Authorities from "../pages/authorities/Authorities";
import Settings from "../pages/globalSettings/Settings";
import PaymentMethod from "../pages/globalSettings/PaymentMethod";
import HeaderTempate from "../pages/globalSettings/HeaderTempate";
import FooterTemplate from "../pages/globalSettings/FooterTemplate";
import PrivacyPolicy from "../pages/globalSettings/PrivacyPolicy";
import TermsAndConditions from "../pages/globalSettings/TermsAndConditions";
import LandingPageTemplate from "../pages/globalSettings/LandingPageTemplate";
import TransactionReport from "../pages/Report/TransactionReport";

import AdminCommission from "../pages/globalSettings/AdminCommission";
import NotFound from "../pages/NotFound";
import Profile from "../pages/Profile";
import ProtectedRoute from "./ProtectedRoute";
import VendorsListForm from "../pages/VendorsListForm";
import AddBrand from "../pages/parkingManagement/vehicleSettings/AddBrand";
import EditBrand from "../pages/parkingManagement/vehicleSettings/EditBrand";
import AddModals from "../pages/parkingManagement/vehicleSettings/AddModals";
import Addpages from "../pages/globalSettings/Addpages";
import Allpages from "../pages/globalSettings/Allpages";
import EditPage from "../pages/globalSettings/EditPage";
import VendorDetails from "../pages/VendorDetails";
import VendorPayOut from "../pages/payments/VendorPayOut";
import AddSubAdmin from "../pages/subAdmin/AddSubAdmin";
import EditSubAdmin from "../pages/subAdmin/EditSubAdmin";
import SubAdminList from "../pages/subAdmin/SubAdminList";

const PageRoutes = () => {
  // const [isUser, SetIsUser] = useState(false)

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Home />} />
        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route path="/vendor" element={<Vendor />} />
        <Route path="/vendorlistform" element={<VendorsListForm />} />
        <Route path="/users" element={<Users />} />
        <Route path="/subadmin" element={<SubAdminList />} />
        <Route path="/addSubAdmin" element={<AddSubAdmin />} />
        <Route path="/editSubAdmin/:id" element={<EditSubAdmin />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/users/userdetail/:id" element={<UserDetails />} />
        <Route path="/vendor/vendordetail/:id" element={<VendorDetails />} />
        <Route path="/report/userreport" element={<UserReport />} />
        <Route path="/report/bookingreport" element={<BookingReport />} />
        <Route path="/report/parkingreport" element={<ParkingReport />} />
        <Route path="/parkingList" element={<ParkingList />} />
        <Route path="/parkingBookings" element={<ParkingBookings />} />
        <Route path="/parkingFacilities" element={<ParkingFacilities />} />
        <Route path="/authorities" element={<Authorities />} />
        <Route path="/vehicleSettings/brand" element={<Brands />} />
        <Route path="/vehicleSettings/brand/:id" element={<EditBrand />} />
        <Route path="/vehicleSettings/brand/create" element={<AddBrand />} />

        <Route path="/vehicleSettings/modal" element={<Modals />} />
        <Route path="/vehicleSettings/modal/create" element={<AddModals />} />

        <Route path="/globalsetting/settings" element={<Settings />} />
        <Route path="/globalsetting/addpages" element={<Addpages />} />
        <Route path="/globalsetting/allpages" element={<Allpages />} />
        <Route path="/globalsetting/editpage/:slug" element={<EditPage />} />

        <Route
          path="/globalsetting/paymentMethod"
          element={<PaymentMethod />}
        />
        <Route
          path="/globalsetting/headerTemplate"
          element={<HeaderTempate />}
        />
        <Route
          path="/globalsetting/footerTemplate"
          element={<FooterTemplate />}
        />
        <Route
          path="/globalsetting/privacyPolicy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/globalsetting/termsAndConditions"
          element={<TermsAndConditions />}
        />
        <Route
          path="/globalsetting/landingPageTemplate"
          element={<LandingPageTemplate />}
        />
        <Route
          path="/globalsetting/adminCommission"
          element={<AdminCommission />}
        />
        <Route path="/payment/vendorpayout" element={<VendorPayOut />} />

        <Route
          path="/report/transactionreport"
          element={<TransactionReport />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default PageRoutes;
