import React, { useContext } from "react";
import profileIcon from "../resorces/profile-icon.png";
import { SidebarContext } from "../utils/SidebarContext";
import { RxHamburgerMenu } from "react-icons/rx";
import dummyImage from "../resorces/dummy-image.jpg";
import { MdOutlinePerson } from "react-icons/md";
import { FaPowerOff } from "react-icons/fa";
import { UseAuth } from "../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "antd";

const Header = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useContext(SidebarContext);
  const auth = UseAuth();
  const handleLogout = () => {
    Modal.confirm({
      title: "Are you sure?",
      okText: "LOG OUT",
      cancelText: "Cancel",
      content: "Do you want to logout?",
      onOk: async () => {
        await auth.logout();
        navigate("/");
      },
    });
  };

  return (
    <>
      <div className="navbar bg-[#35415B]">
        <div className="navbar-start">
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className={`p-1.5 text-white ${expanded ? "block" : "hidden"}`}
          >
            <RxHamburgerMenu />
          </button>
        </div>

        <div className="navbar-end me-5">
          <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle avatar"
            >
              <div className="w-10 rounded-full">
                <img alt="Tailwind CSS Navbar component" src={profileIcon} />
              </div>
            </div>
            <ul
              tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-white rounded-sm w-52"
            >
              <li>
                <Link
                  to={"/profile"}
                  // onClick={() => navigate('/profile')}

                  className=" flex items-center  gap-5 "
                >
                  <div>
                    <img
                      src={dummyImage}
                      height={55}
                      width={55}
                      className="rounded-full"
                      alt="dummy"
                    />
                  </div>
                  <div>
                    <p className="font-bold">Tom</p>
                    <span className="">Admin</span>
                  </div>
                </Link>
              </li>
              <li className="flex ">
                {/* <Link>
                  <MdOutlinePerson />
                  Settings
                </Link> */}
              </li>
              <li>
                <Link onClick={handleLogout}>
                  <FaPowerOff />
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
