import React, { useCallback, useContext, useEffect, useState } from "react";
import { SidebarContext } from "../utils/SidebarContext";
import Header from "../layout/Header";
import axios from "../axios";
import { UseAuth } from "../Context/AuthContext";
import Loader from "../utils/Loader";
import { Link } from "react-router-dom";
import { Modal, notification } from "antd";

const Profile = () => {
  const auth = UseAuth();

  // ---------------------------------------------------- All States ------------------------------------------

  const [userType, setUserType] = useState("");

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    image: null,
  });
  const [email, setEmail] = useState(localStorage.getItem("Email") || "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [expanded, setExpanded] = useContext(SidebarContext);

  const [isLoading, setIsLoading] = useState();

  const [isError, setIsError] = useState(false);

  const [passUpdate, setPassUpdate] = useState(false);
  const [profileUpdate, setProfileUpdate] = useState(false);

  // console.log(auth.token)

  // ---------------------------------------------------- Get Profile ------------------------------------------

  const getProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("admin/get-profile-details", {
        headers: {
          Authorization: auth.token,
        },
      });
      setIsLoading(false);
      if (response.status === 200) {
        setProfileData({
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
          image: response?.data?.data?.image,
        });
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }, []);

  // ---------------------------------------------------- Updating password ------------------------------------------
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Handle form submission here
    if (newPassword !== "" && oldPassword !== "" && confirmPassword !== "") {
      try {
        if (confirmPassword === newPassword) {
          const response = await axios.post(
            "admin/chnage-password",
            {
              oldPassword: oldPassword,
              newPassword: newPassword,
              confirmPassword: confirmPassword,
            },
            {
              headers: {
                Authorization: auth.token,
              },
            }
          );
          console.log(response);
          setIsLoading(false);
          notification.success({
            message: "success",
            description: response.data.msg || "Error While Updating Password",
          });
          setConfirmPassword("");
          setNewPassword("");
          setOldPassword("");
          setPassUpdate(false);
          setIsError(false);
        } else {
          setIsError("Confirm and New Password not matched");
          // Modal.error({
          //     content: 'Confirm and New Password not matched',
          // })
          setIsLoading(false);
        }
      } catch (error) {
        console.warn("error while updating profile", error);
        setIsLoading(false);
        const errorResponse = error.response.data.msg;
        setIsError(errorResponse);
        // alert(errorResponse)
        notification.error({
          message: "Error",
          description:
            error.response.data.errors ||
            error.response.data.msg ||
            "Error While Updating Password",
        });
      }
    } else {
      notification.error({
        message: "Error",
        description: "Please fill all the fields",
      });
      setIsLoading(false);
    }
  };

  // -----------------------------------------------------Updating profile update --------------------------------------

  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post(
        "admin/update-profile",
        {
          name: profileData?.name,
          profileImage: profileData?.image,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: response?.data?.msg || "Profile Updated Successfully",
        });
        setProfileUpdate(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Error",
          description:
            response?.data?.msg ||
            response?.errors ||
            "Error While Updating Profile",
        });
      }
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Error",
        description: error.response.data.msg || "Error While Updating Profile",
      });
    }
  };

  // ---------------------------------------------------- Effect Call ------------------------------------------

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Header />

      <div
        className={`p-6 font-[poppins] h-screen overflow-auto pb-[5rem]  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Profile</div>

          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Profile</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}

        {isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div className="m-auto p-auto w-5/6 border rounded-md relative my-[5rem]">
              <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
                Profile Details
              </div>
              <div className="m-auto">
                <div className="">
                  <label className="form-control mx-5 mt-[3rem] ">
                    <div className="label">
                      <span className="label-text font-semibold">Name</span>
                    </div>
                    <input
                      value={profileData.name}
                      type="name"
                      placeholder="name here"
                      className="input input-bordered  "
                    />
                  </label>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1">
                  <label className="form-control mx-5 mt-[1rem] mb-[1rem]">
                    <div className="label">
                      <span className="label-text font-semibold">Email </span>
                    </div>
                    <input
                      value={profileData.email}
                      type="email"
                      placeholder="Email here"
                      className="input input-bordered  "
                    />
                  </label>
                </div>
                <Modal
                  okText="Update Paasword"
                  cancelText="cancel"
                  onOk={handleUpdate}
                  open={passUpdate}
                  onCancel={() => setPassUpdate(!passUpdate)}
                >
                  <h2 className="text-2xl font-bold mb-6 text-center">
                    Update Password
                  </h2>

                  <label className="form-control mx-5 mt-[1rem] mb-[1rem]">
                    <div className="label">
                      <span className="label-text font-semibold">
                        Old Password{" "}
                      </span>
                    </div>
                    <input
                      type="text"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      placeholder="Type here New Password"
                      className="input input-bordered  "
                    />
                    {isError === "Your current password is wrong." && (
                      <p className="error">*Old password is incorrect</p>
                    )}
                  </label>
                  <label className="form-control mx-5 mt-[1rem] mb-[1rem]">
                    <div className="label">
                      <span className="label-text font-semibold">
                        New Password{" "}
                      </span>
                    </div>
                    <input
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      type="password"
                      placeholder="Type here New Password"
                      className="input input-bordered  "
                    />
                    {isError === "Confirm and New Password not matched" && (
                      <p className="error">
                        *new password and confirm password nat matched
                      </p>
                    )}
                  </label>
                  <label className="form-control mx-5 mt-[1rem] mb-[1rem]">
                    <div className="label">
                      <span className="label-text font-semibold">
                        Confirm Password{" "}
                      </span>
                    </div>
                    <input
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      type="text"
                      placeholder="Confirm password "
                      className="input input-bordered  "
                    />
                    {isError === "Confirm and New Password not matched" && (
                      <p className="error">
                        *new password and confirm password nat matched
                      </p>
                    )}
                  </label>
                </Modal>
                <Modal
                  okText="Update Profile"
                  cancelText="cancel"
                  onOk={updateProfile}
                  open={profileUpdate}
                  onCancel={() => setProfileUpdate(!profileUpdate)}
                >
                  <div className="max-w-md mx-auto mt-10 p-6  ">
                    <h2 className="text-2xl font-bold mb-6 text-center">
                      Update Profile
                    </h2>
                    <form>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="name"
                        >
                          Name
                        </label>
                        <input
                          id="name"
                          type="text"
                          value={profileData.name}
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              name: e.target.value,
                            })
                          }
                          placeholder="Enter your name"
                          className=" input shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="profileImage"
                        >
                          Profile Image
                        </label>
                        <input
                          id="profileImage"
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              image: e.target.files[0],
                            })
                          }
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </form>
                  </div>
                </Modal>
              </div>
            </div>
            <div className="flex items-center gap-4 justify-center">
              <button
                className=" border w-fit px-2 py-1 rounded-md bg-[#35415B] flex justify-between text-white"
                onClick={() => setProfileUpdate(!passUpdate)}
              >
                {/* <LuDownload size={20} className="me-1" />{" "} */}
                <p className=" ">Update Profile</p>
              </button>
              <button
                className=" border w-fit px-2 py-1 rounded-md bg-[#35415B] flex justify-between text-white"
                onClick={() => setPassUpdate(!passUpdate)}
              >
                {/* <LuDownload size={20} className="me-1" />{" "} */}
                <p className=" ">Change Password</p>
              </button>
            </div>
          </>
        )}
      </div>
      <div></div>
    </>
  );
};

export default Profile;
