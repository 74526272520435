import React, { useCallback, useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header'
import { Link } from 'react-router-dom'
import { SidebarContext } from '../../utils/SidebarContext';
import DataTable from '../../components/DataTable';
import axios from '../../axios';
import { UseAuth } from '../../Context/AuthContext';

const Allpages = () => {
    const [expanded, setExpanded] = useContext(SidebarContext);
    const auth = UseAuth()



    const [allPages, setAllPages] = useState([])

    const deletePage = () => {

    }


    const columns = [
        {
            title: 'Page Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        // {
        //     title: 'Content',
        //     dataIndex: 'content',
        //     key: 'content',
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <span className='flex gap-3'>
                    <Link to={`/globalsetting/editpage/${record.slug}`} className="btn btn-sm btn-primary">
                        Edit
                    </Link>
                    <Link>
                        <button className="btn btn-sm btn-danger" onClick={() => deletePage(record._id)}> Delete  </button>
                    </Link>
                </span>
            )
        }
    ]


    const pagesData = useCallback(async () => {
        try {
            const resp = await axios.get('admin/page', {
                headers: {
                    Authorization: auth.token
                }
            })
            console.log(resp.data.data);

            if (resp.status === 200) {
                setAllPages(resp?.data?.data)
            }

        } catch (error) {
            console.error(error);
        }
    }, [])

    useEffect(() => {
        pagesData()
    }, [])

    return (
        <div>
            <Header />

            <div
                className={`p-6 font-[poppins] h-screen overflow-scroll pb-[5rem]  ${expanded ? "" : " md:ms-[3rem] "
                    } `}
            >
                <div className="flex justify-between items-center mb-5">
                    <div className="text-xl font-medium text-[#35415B]">
                        Pages List
                    </div>
                    <div className="">
                        <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
                            <ul>
                                <li>
                                    <Link to={'/'} >Dashboard</Link>
                                </li>
                                <li>
                                    <span>All Pagess</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-4">
                            <DataTable
                                columns={columns}
                                dataSource={allPages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allpages