import PageRoutes from "./Routes/Routes";
import Sidebar from "./layout/Sidebar";
import AuthRoutes from "./Routes/AuthRoutes";
import { UseAuth } from "./Context/AuthContext";
import "./App.css";
import { Toaster } from "react-hot-toast";

function App() {
  const auth = UseAuth();
  const isUser = auth.isUserAuthenticated;
  return (
    <>
      {isUser ? (
        <>
          <Toaster />
          <div className="flex bg-slate-50 h-full">
            <Sidebar />
            <main className="w-full h-screen overflow-y-auto scrollbar">
              <PageRoutes />
            </main>
          </div>
        </>
      ) : (
        <>
          <main className="w-full h-screen overflow-y-auto scrollbar">
            <AuthRoutes />
          </main>
        </>
      )}
    </>
  );
}

export default App;
