import React, { useCallback, useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../utils/SidebarContext";
import Header from "../../layout/Header";
import { LuDownload } from "react-icons/lu";
import { Link } from "react-router-dom";
import { Select } from "antd";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";

const BookingReport = () => {
  const auth = UseAuth();
  const [expanded, setExpanded] = useContext(SidebarContext);

  const [user, setUser] = useState([]);
  const [parkingData, setParkingData] = useState([]);

  const [selectedData, setSelectedData] = useState({
    user: null,
  });

  const getUserData = useCallback(async () => {
    try {
      const response = await axios.get("admin/vendor", {
        headers: {
          Authorization: auth.token,
        },
      });
      if (response.status === 200) {
        setUser(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  const getParkingData = useCallback(async () => {
    try {
      const response = await axios.get(`admin/parking/${selectedData.user}`, {
        headers: {
          Authorization: auth.token,
        },
      });
      if (response.status === 200) {
        console.log(response.data.data);
        const data = response?.data?.data?.filter(
          (e) => e?.vendor?._id === selectedData.user
        );
        setParkingData(response?.data?.data);
        console.log("wow data", data);
      }
    } catch (error) {
      console.log(error, "error while getting parking list");
    }
  }, [selectedData.user]);

  useEffect(() => {
    getParkingData();
  }, [selectedData.user]);

  const userData = user?.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));
  const parkingDataValue = parkingData?.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll pb-[5rem]  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Booking Report
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Booking Report</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}
        <div className="m-auto p-auto w-5/6 border rounded-md relative my-[5rem]">
          <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
            Booking Report
          </div>
          <div className="m-auto">
            <div className="grid grid-cols-2 gap-4">
              <label className="form-control mx-5 mt-[3rem] ">
                <div className="label">
                  <span className="label-text font-semibold">Select User </span>
                </div>
                <Select
                  options={userData}
                  placeholder="Select user"
                  onChange={(e) =>
                    setSelectedData({
                      ...selectedData,
                      user: e,
                    })
                  }
                />
              </label>
              <label className="form-control mx-5 mt-[3rem] ">
                <div className="label">
                  <span className="label-text font-semibold">
                    Select Parking{" "}
                  </span>
                </div>
                <Select
                  options={parkingDataValue}
                  placeholder="Select Parking"
                />
              </label>
              <label className="form-control mx-5 mt-[1rem]">
                <div className="label">
                  <span className="label-text font-semibold">
                    Select Booking Status{" "}
                  </span>
                </div>
                <Select placeholder="Select Booking Status" />
              </label>
              <label className="form-control mx-5 mt-[1rem]">
                <div className="label">
                  <span className="label-text font-semibold">
                    Select Payment Method{" "}
                  </span>
                </div>
                <Select placeholder="Select Payment Method" />
              </label>
            </div>
            <label className="form-control mx-5 mt-[3rem] mb-[2rem]">
              <div className="label">
                <span className="label-text font-semibold">Select Date </span>
              </div>
              <input
                type="date"
                placeholder="Type here"
                className="input input-bordered bg-white "
              />
            </label>
          </div>
        </div>
        <div
          className="m-auto border w-fit px-2 py-1 rounded-md bg-[#35415B] flex justify-between text-white"
          role="button"
        >
          <LuDownload size={20} className="me-1" />{" "}
          <p className=" ">Download</p>
        </div>
      </div>
    </>
  );
};

export default BookingReport;
