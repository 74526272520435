// import { Table } from 'ant-table-extensions'
import { Table } from "ant-table-extensions";
import React, { useState } from "react";

const DataTable = ({
  columns,
  dataSource,
  exportable,
  searchable,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <React.Fragment>
      <Table
        rowSelection={rowSelection}
        rowKey="_id"
        exportable
        searchable
        columns={columns}
        dataSource={dataSource}
        pagination={{ defaultPageSize: 5 }}
      />
      {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
    </React.Fragment>
  );
};

export default DataTable;
