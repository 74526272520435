import React, { useCallback, useContext, useEffect, useState } from "react";

import "../style.css";
import { Button, Table } from "antd";
import Header from "../../layout/Header";
import { SidebarContext } from "../../utils/SidebarContext";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import DataTable from "../../components/DataTable";

const ParkingList = () => {
  const [expanded, setExpanded] = useContext(SidebarContext);
  const auth = UseAuth();

  const [parkingData, setParkingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getParkingDataApi = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("admin/parking", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${auth.token}`,
        },
      });
      setParkingData(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error, "error");
    }
  }, []);

  useEffect(() => {
    getParkingDataApi();
  }, []);

  const columns = [
    {
      title: "Serial Id",
      render: (text, elem, index) => index + 1,
    },
    {
      title: "Image",
      dataIndex: "parkingImage",
      key: "parkingImage",
      render: (text, elem, index) => (
        <img
          src={
            elem?.parkingImage?.[0] ||
            "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
          }
          alt="img"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "User",
      dataIndex: "User",
      key: "User",
      render: (text, elem, index) => <div>{elem?.vendorName}</div>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text, elem, index) => (
        <div>{elem?.address || elem?.location}</div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Parking List</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Parking List</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>
          <div className=" mb-3 flex justify-center items-center">
            {/* <div className="btn bg-[#f07e01] border-0 text-white font-normal dark:hover:text-white hover:text-black text-lg mx-3 px-5 py-3">
              + Create Parking
            </div> */}
          </div>
          <div className="pb-5 overflow-auto">
            <DataTable
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={parkingData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingList;
