import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UseAuth } from "../Context/AuthContext";
import axios from "../axios";

const ProtectedRoute = () => {
  const auth = UseAuth();

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.get("admin/get-profile-details", {
          headers: {
            Authorization: auth.token,
          },
        });
      } catch (error) {
        auth.logout();
      }
    };
    getUser();
  }, []);

  if (!auth.isUserAuthenticated) return <Navigate to={"/"} />;
  return <Outlet />;
};

export default ProtectedRoute;
