import {
  React,
  PureComponent,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
// import Sidebar from "../layout/Sidebar";
import Header from "../layout/Header";
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Rectangle,
  ComposedChart,
  Area,
  Line,
  Scatter,
} from "recharts";
import { LuDownload, LuPanelTopInactive } from "react-icons/lu";
import { SidebarContext } from "../utils/SidebarContext";
import {
  MdCancelPresentation,
  MdHome,
  MdOutlineWorkspaces,
} from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { FaParking, FaWallet } from "react-icons/fa";
import { IoGlobe } from "react-icons/io5";
import { HiMiniClipboardDocumentCheck } from "react-icons/hi2";
import axios from "../axios";
import { UseAuth } from "../Context/AuthContext";
import { Select } from "antd";

const Home = () => {
  const auth = UseAuth();

  const [dashData, setDashData] = useState({
    totalBooking: 0,
    todayTotalUsers: 0,
    tadayTotalParking: 0,
    todayTotalEarnings: 0,
    todayTotalCommissions: 0,
    todayBookingPlaced: 0,
    todayBookingActive: 0,
    todayBookingCompleted: 0,
    totalBookingCanceled: 0,
  });
  const [graphData, setGraphData] = useState([]);
  const [selected, setSelected] = useState("Yearly");

  const getDashData = useCallback(async () => {
    try {
      const res = await axios.get("admin/reports/dashboard", {
        headers: {
          Authorization: auth.token,
        },
      });
      if (res.status === 200) {
        const data = res?.data?.data;
        setDashData({
          totalBooking: data?.totalBooking,
          todayTotalUsers: data?.todayTotalUsers,
          tadayTotalParking: data?.tadayTotalParking,
          todayTotalEarnings: data?.todayTotalEarnings,
          todayTotalCommissions: data?.todayTotalCommissions,
          todayBookingPlaced: data?.todayBookingPlaced,
          todayBookingActive: data?.todayBookingActive,
          todayBookingCompleted: data?.todayBookingCompleted,
          totalBookingCanceled: data?.totalBookingCanceled,
          totalSalesGraphJson: data?.totalSalesGraphJson,
        });
      }
    } catch (error) {
      console.log(error, "error while data fetching");
    }
  }, []);

  const getGraphData = useCallback(async () => {
    try {
      const res = await axios.get(`admin/reports/graph/${selected}`, {
        headers: {
          Authorization: auth.token,
        },
      });
      if (res.status === 200) {
        setGraphData(res?.data?.data);
      }
    } catch (error) {
      setGraphData([]);
      console.log(error, "error while data fetching");
    }
  }, [selected]);

  useEffect(() => {
    getDashData();
  }, []);
  useEffect(() => {
    getGraphData();
  }, [selected]);

  // const data = [
  //   {
  //     name: "JAN",
  //     Subscriptions: 2780,
  //   },
  //   {
  //     name: "FAB",
  //     Subscriptions: 4000,
  //   },
  //   {
  //     name: "MAR",
  //     Subscriptions: 3000,
  //   },
  //   {
  //     name: "APR",
  //     Subscriptions: 2000,
  //   },
  //   {
  //     name: "MAY",
  //     Subscriptions: 5000,
  //   },
  //   {
  //     name: "JUN",
  //     Subscriptions: 3000,
  //   },
  //   {
  //     name: "JUL",
  //     Subscriptions: 2000,
  //   },
  // ];

  const data = [...graphData];

  const options = [
    {
      id: 1,
      name: "Yearly",
      value: "Yearly",
    },
    {
      id: 2,
      name: "Monthly",
      value: "Monthly",
    },
    {
      id: 3,
      name: "Weekly",
      value: "Weekly",
    },
  ];

  // const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const arr = [
    {
      id: 1,
      title: "Total Orders",
      value: dashData.totalBooking,
      icon: <MdHome />,
    },
    {
      id: 2,
      title: "Today'a Total Users",
      value: dashData.todayTotalUsers,
      icon: <HiUsers />,
    },
    {
      id: 3,
      title: "Today's Total parking",
      value: dashData.tadayTotalParking,
      icon: <FaParking />,
    },
    {
      id: 4,
      title: "Today'a Total Earnings",
      value: Number(dashData.todayTotalEarnings).toFixed(2),
      icon: <IoGlobe />,
    },
    {
      id: 5,
      title: "Today's Total Commission",
      value: Number(dashData.todayTotalCommissions).toFixed(2),
      icon: <FaWallet />,
    },
    {
      id: 6,
      title: "Today'a Booking Placed",
      value: dashData.todayBookingPlaced,
      icon: <MdOutlineWorkspaces />,
    },
    {
      id: 7,
      title: "Today's Booking Active",
      value: dashData.todayBookingActive,
      icon: <LuPanelTopInactive />,
    },
    {
      id: 2,
      title: "Today'a Booking Completed",
      value: dashData.todayBookingCompleted,
      icon: <HiMiniClipboardDocumentCheck />,
    },
    {
      id: 1,
      title: "Total Booking Cancelled",
      value: dashData.totalBookingCanceled,
      icon: <MdCancelPresentation />,
    },
  ];

  const [expanded, setExpanded] = useContext(SidebarContext);

  return (
    <>
      <Header />
      <div
        className={`p-6 text-[poppins] h-screen overflow-scroll ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center">
          <div className="text-xl font-medium text-[#35415B]">
            Today's Statistics Data
          </div>
        </div>

        <div className="flex flex-wrap gap-4 my-3">
          {arr.map((item, index) => {
            return (
              <div
                key={index}
                className=" card w-[100%] md:w-[40%] xl:w-[32%] hover:shadow-2xl transition-all bg-white shadow-md md:ms-0"
              >
                <div className="card-body ">
                  <div className="card-actions ">
                    <p className="text-black  font-semibold ">{item.title}</p>
                    <button className="btn flex items-center text-xl justify-center  rounded-full  bg-[#35415B] text-[#F07E01] btn-sm">
                      {item.icon}
                    </button>
                  </div>
                  <p className="font-semibold">{item.value}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="grid grid-cols-1 ">
          <div className="lg:col-span-3 py-5">
            <div className="flex justify-between items-center">
              <div className=" text-xl font-medium text-[#35415B]">
                Total Sales
              </div>
              <div className=" flex items-center">
                <Select
                  style={{
                    minWidth: 100,
                  }}
                  value={selected}
                  placeholder="select time"
                  options={options}
                  onChange={(e) => setSelected(e)}
                />
                {/* <LuDownload
                  size={35}
                  className="font-extrabold"
                  role="button"
                /> */}
              </div>
            </div>
            <div className="">
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                  width={500}
                  height={400}
                  data={data}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid stroke="#f1f1f1" />
                  <XAxis dataKey="name"></XAxis>
                  <YAxis
                    label={{
                      // value: "SUBSCRIPTIONS",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Bar dataKey="Subscriptions" fill="#E0440E" barSize={20} />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
