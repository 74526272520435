import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../layout/Header";
import { GetData, GetRequest, postRequest } from "../../utils/helper/Index";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "../../axios";
import DataTable from "../../components/DataTable";
import { Modal, Switch, Tooltip } from "antd";
import { SidebarContext } from "../../utils/SidebarContext";
import { UseAuth } from "../../Context/AuthContext";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function SubAdminList() {
  const [subAdmins, setSubAdmins] = useState([]);
  // const [token, setToken] = useState(Cookies.get("token"));
  const [expanded, setExpanded] = useContext(SidebarContext);
  const [modal, setModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [updateList, setUpdateList] = useState(false);

  const auth = UseAuth();
  const token = auth.token;

  const fetchSubAdmins = async () => {
    try {
      const res = await axios.get("admin/list-sub-admin", {
        headers: {
          Authorization: token,
        },
      });
      setSubAdmins(res?.data?.data);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch sub-admins.");
    }
  };

  useEffect(() => {
    fetchSubAdmins();
  }, [token, updateList]);

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this sub Admin?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await axios.delete(`admin/delete-sub-admin/${id}`, {
            headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            Modal.success({
              title: response.data.msg || "Deleted Successfully",
            });
            fetchSubAdmins();
          }
        } catch (error) {
          Modal.error({
            title: error.message || "Error",
          });
          console.log(error);
        }
      },
    });
  };

  const columns = [
    {
      title: "All",
      render: (_, elem, index) => (
        <div className="d-flex justify-content-between">{index + 1}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, elem) => (
        <>
          <span>{elem?.name}</span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, elem) => (
        <>
          <span>{elem?.email}</span>
        </>
      ),
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      render: (_, elem) => (
        <>
          <span>{elem?.mobile}</span>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (_, elem) => (
        <>
          <span>{new Date(elem?.updatedAt).toDateString()}</span>
        </>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (elem) => (
        <>
          <div className="flex justify-center gap-2">
            <div className=" text-center px-2 py-1">
              <Tooltip title="Edit">
                <Link
                  className="dropdown-item border-radius-md "
                  to={`/editSubAdmin/${elem?._id}`}
                >
                  <FaPenToSquare size={24} color="#E8A219" />
                </Link>
              </Tooltip>
            </div>
            <div
              className=" text-center px-2 py-1"
              role="button"
              onClick={() => {
                // setModal(!modal);
                // setDeleteId(elem?._id);
                handleDelete(elem?._id);
              }}
            >
              <Tooltip title="Delete">
                <RiDeleteBin6Line size={24} color="#E8A219" />
              </Tooltip>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Sub Admin</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to="/">Dashboard</Link>
                </li>
                <li>
                  <span>Sub Admin</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="pb-5 overflow-x-auto">
            <DataTable columns={columns} dataSource={subAdmins} />
          </div>
        </div>
      </div>
      <dialog id="my_modal_1" className={`modal ${modal ? "modal-open" : ""}`}>
        <div className="modal-box bg-white text-black">
          <h3 className="font-bold text-lg">Warning!</h3>
          <p className="py-4">Do You Want To Delete This Sub Admin</p>
          <div className="modal-action">
            <button
              className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
              onClick={() => setModal(!modal)}
            >
              ✕
            </button>
            <form method="dialog">
              {/* if there is a button in form, it will close the modal */}
              <button
                className="btn btn-error text-white "
                onClick={() => {
                  handleDelete();
                  setModal(!modal);
                }}
              >
                Delete
              </button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}
