import { useState, useEffect } from "react";
import Header from "../../layout/Header";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "../../axios";
import { useParams } from "react-router-dom";
import { UseAuth } from "../../Context/AuthContext";

export default function EditSubAdmin() {
  const permissionsList = [
    "DASHBOARD_ACCESS",
    "USER_ACCESS",
    "VENDOR_ACCESS",
    "REPORT_ACCESS",
    "PARKING_ACCESS",
    "BOOKING_ACCESS",
    "FACILITIES_ACCESS",
    "VEHICLE_SETTINGS_ACCESS",
    "PAYMENTS_ACCESS",
    "AUTHORITIES_ACCESS",
    "SETTINGS_ACCESS",
  ];

  const [subAdmin, setSubAdmin] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    profilePhoto: null,
    permission: [],
  });
  const auth = UseAuth();
  const token = auth.token;
  const [errorMessage, setErrorMessage] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const params = useParams();

  useEffect(() => {
    const fetchSubAdmins = async () => {
      try {
        const res = await axios.get("admin/list-sub-admin", {
          headers: {
            Authorization: token,
          },
        });
        const filteredSubAdmin = res?.data?.data.find(
          (subAdmin) => subAdmin._id === params?.id
        );
        if (filteredSubAdmin) {
          setSubAdmin({
            name: filteredSubAdmin.name,
            mobile: filteredSubAdmin.mobile,
            email: filteredSubAdmin.email,
            password: filteredSubAdmin.password,
            profilePhoto: null,
            permission: filteredSubAdmin.permission,
          });
        } else {
          toast.error("Sub-admin not found.");
        }
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch sub-admins.");
      }
    };
    fetchSubAdmins();
  }, [token, params?.id]);
  // console.log("subAdmin.permission", subAdmin);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    console.log("type ", type, " checked ", checked, " value ", value);

    if (type === "checkbox") {
      setSubAdmin((prevData) => {
        const permissions = checked
          ? [...prevData.permission, value]
          : prevData.permission.filter((perm) => perm !== value);

        return { ...prevData, permission: permissions };
      });
    } else if (type === "file") {
      setSubAdmin((prevData) => ({ ...prevData, [name]: files[0] }));
    } else {
      setSubAdmin((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (subAdmin?.mobile?.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      isValid = false;
    }

    if (subAdmin?.permission?.length === 0) {
      setPermissionError("At least one permission must be selected.");
      isValid = false;
    }

    if (isValid) {
      try {
        const res = await axios.post(
          `admin/update-sub-admin/${params?.id}`,
          {
            name: subAdmin.name,
            mobile: subAdmin.mobile,
            email: subAdmin.email,
            password: subAdmin.password,
            permission: subAdmin.permission,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        toast.success(res?.data?.msg);
        setErrorMessage("");
        setPermissionError("");
      } catch (error) {
        console.log(error);
      }
    }
  };
  // console.log(subAdmin.permission, "subAdmin");

  return (
    <>
      <Header />
      <div className="flex justify-center items-center h-screen bg-gray-100 p-4">
        <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Edit Sub Admin
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={subAdmin?.name}
                  onChange={handleChange}
                  placeholder="Enter name"
                  className="shadow appearance-none border rounded w-full bg-white py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={subAdmin?.mobile}
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={subAdmin?.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Enter password"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="profilePhoto"
              >
                Profile Photo
              </label>
              <input
                type="file"
                id="profilePhoto"
                name="profilePhoto"
                onChange={handleChange}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Permissions
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {permissionsList.map((permission) => (
                  <label key={permission} className="flex items-center">
                    <input
                      type="checkbox"
                      name="permission"
                      value={permission}
                      checked={subAdmin?.permission?.includes(permission)}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm">
                      {permission.replace(/_/g, " ")}
                    </span>
                  </label>
                ))}
              </div>
            </div>
            {permissionError && (
              <p className="text-red-500 text-xs italic">{permissionError}</p>
            )}
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="m-auto border w-fit px-2 py-1 rounded-md bg-[#35415B] flex justify-between text-white"
              >
                Save Changes
              </button>
            </div>
            {errorMessage && (
              <p className="text-red-500 text-xs italic">{errorMessage}</p>
            )}
          </form>
        </div>
      </div>
    </>
  );
}
