import React, { useContext, useEffect, useState } from "react";

import "../style.css";
import Header from "../../layout/Header";
import { SidebarContext } from "../../utils/SidebarContext";
// import { MdOutlineFormatListBulleted } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import { Table } from "ant-table-extensions";

const VendorPayOut = () => {
  const auth = UseAuth();

  const [expanded, setExpanded] = useContext(SidebarContext);

  const [payoutData, setPayoutData] = useState([]);
  const [totalComPercentage, setTotalComPercent] = useState("");

  const getPayoutData = async () => {
    try {
      const res = await axios.get("admin/vendor-payout-report", {
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.token,
        },
      });
      if (res.data.statusCode === 200) {
        setPayoutData(res?.data?.data?.vendorPayouts);
        setTotalComPercent(res?.data?.data?.adminComissionPercent);
      } else {
        setPayoutData([]);
      }
    } catch (error) {
      console.log(error, "error while payout data");
    }
  };

  const columns = [
    // Table.EXPAND_COLUMN,
    {
      title: "Sl No.",
      key: "_id",
      dataIndex: "_id",
      render: (_, elem, index) => (
        <div className="d-flex align-items-center justify-content-center">
          <span className="text-muted">{index + 1}</span>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
    },
    {
      title: "Total Payout Amount",
      dataIndex: "totalPayoutAmount",
      render: (amount) => {
        return Number(amount).toFixed(2);
      },
    },
    {
      title: `Total Admin Comission (${totalComPercentage}%) `,
      dataIndex: "totalAdminComission",
      render: (amount) => {
        return Number(amount).toFixed(2);
      },
    },
    {
      title: "Payable Amount",
      dataIndex: "payableAmount",
      render: (amount) => {
        return Number(amount).toFixed(2);
      },
    },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    // },
  ];
  useEffect(() => {
    getPayoutData();
  }, []);

  const data = [...payoutData];

  //   const expandedRowRender = (record) => (
  //     <div className="bg-gray-100 flex flex-col gap-10 items-center justify-center p-4">
  //       <table>
  //         <tr>
  //           <th>Order Id </th>
  //           <th>Product Name</th>
  //           <th>Service Name</th>
  //           <th>Price </th>
  //           <th>Order Date</th>
  //         </tr>

  //       </table>
  //     </div>
  //   );
  const expandedRowRender = (record) => (
    <div className="bg-gray-100 flex flex-col gap-10 items-center justify-center p-4">
      <table>
        <tr>
          <th>Name</th>
          <th>Vendor Name</th>
          <th>Opening Time</th>
          <th>Closing Time </th>
          <th>Total Bookings</th>
          <th>Total Revenue </th>
          <th>Average Revenue</th>
        </tr>
        {record.parkingReports.map((data, index) => {
          return (
            <>
              <tr className="my-2">
                <td align="center">
                  <span>{data?.name || "N/A"}</span>
                </td>
                <td align="center">
                  <span>{data?.vendorName || "N/A"}</span>
                </td>
                <td align="center">
                  <span>{data?.openingTime || "N/A"}</span>
                </td>
                <td align="center">
                  <span>{data?.closingTime || "N/A"}</span>
                </td>
                <td align="center">
                  <span>{data?.totalBookings || "N/A"}</span>
                </td>
                <td align="center">
                  <span>{Number(data?.totalRevenue).toFixed(2) || "N/A"}</span>
                </td>
                {/* <td align="center">
                  <span>{data?.price.toFixed(1) || "N/A"}</span>
                </td> */}
                <td align="center">
                  <span>
                    {Number(data?.averageRevenue).toFixed(2) || "N/A"}
                  </span>
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Vendor Payout
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <Link to={"/parkingList"}>Vendor Payout </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>

          <div className="pb-5">
            <Table
              rowKey="_id"
              expandable={{
                expandedRowRender,
              }}
              columns={columns}
              dataSource={data}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorPayOut;
