import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import "../style.css";
import { SidebarContext } from "../../utils/SidebarContext";
import axios from "../../axios.js";
import { UseAuth } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable.jsx";
import { Switch } from "antd";
import toast from "react-hot-toast";

const Users = () => {
  const auth = UseAuth();

  const [allUser, setAllUser] = useState([]);

  useEffect(() => {
    getAllUserDetails();
  }, []);

  const getAllUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`admin/customer`, {
        headers: {
          Authorization: auth.token,
        },
      });
      setAllUser(response?.data?.data);
    } catch (error) {
      console.log("Error while fetching user detail", error);
    }
  }, []);
  const handleChangeStatus = async (id, status) => {
    try {
      const response = await axios.post(
        "admin/block-unblock-user-vendor",
        {
          userId: id,
          status: !status,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        getAllUserDetails();
        toast.success("Status Updated");
      }
    } catch (error) {
      console.error("Error while fetching user detail", error);
    }
  };

  const [expanded, setExpanded] = useContext(SidebarContext);
  const columns = [
    {
      title: "All",
      render: (_, elem, index) => (
        <div className="d-flex justify-content-between">{index + 1}</div>
      ),
    },
    {
      title: "Image",
      dataIndex: "profileImg",
      render: (_, elem) => (
        <>
          <img
            src={`${
              elem?.profileImgUrl ||
              "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
            }`}
            alt="no img here "
            height={50}
            width={50}
          />
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, elem) => (
        <>
          <span>{elem?.name}</span>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, elem) => (
        <>
          <span>{elem?.email}</span>
        </>
      ),
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
      render: (_, elem) => (
        <>
          <span>{elem?.mobile}</span>
        </>
      ),
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (_, elem) => (
        <>
          <span>{new Date(elem?.updatedAt).toDateString()}</span>
        </>
      ),
    },

    {
      title: "Active",
      dataIndex: "status",
      render: (_, elem) => (
        <div className=" text-left px-2 py-1">
          <div className=" text-left">
            <Switch
              onChange={() => handleChangeStatus(elem?._id, elem?.status)}
              checked={elem?.status}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Total Bookings",
      dataIndex: "totalbookings",
      render: (_, elem) => (
        <>
          <span>{elem?.totalBooking}</span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (elem) => (
        <div className=" text-center px-2 py-1">
          <Link
            className="dropdown-item border-radius-md"
            to={`userdetail/${elem?._id}`}
          >
            View
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Users</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to="/">Dashboard</Link>
                </li>
                <li>
                  <span>Users</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="pb-5 overflow-x-auto">
            <DataTable columns={columns} dataSource={allUser} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
