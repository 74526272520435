import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import "../style.css";
import { SidebarContext } from "../../utils/SidebarContext";
import axios from "../../axios.js";
import { UseAuth } from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable.jsx";
import { Switch } from "antd";

const ParkingTicket = () => {
  const auth = UseAuth();

  const [allUser, setAllUser] = useState([]);

  useEffect(() => {
    // getAllUserDetails();
  }, []);

  const getAllUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`admin/customer`, {
        headers: {
          Authorization: auth.token,
        },
      });
      setAllUser(response?.data?.data);
    } catch (error) {
      console.log("Error while fetching user detail", error);
    }
  }, []);

  const [expanded, setExpanded] = useContext(SidebarContext);

  return (
    <>
      {/* <Header /> */}
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-center items-center mb-5 ">
          <div className="text-xl font-medium text-[#35415B]">
            Parking Ticket
          </div>
          <div className=""></div>
        </div>

        <div className="mb-5">
          <div className="w-[80%] border-2 border-dashed rounded border-[#35415B] m-auto grid grid-cols-1 transition-all lg:grid-cols-3 divide-x-2 items-center">
            <div className="p-5">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-28 sm:size-36 md:size-64 m-auto transition-all"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 3.75 9.375v-4.5ZM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 0 1-1.125-1.125v-4.5ZM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0 1 13.5 9.375v-4.5Z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 6.75h.75v.75h-.75v-.75ZM6.75 16.5h.75v.75h-.75v-.75ZM16.5 6.75h.75v.75h-.75v-.75ZM13.5 13.5h.75v.75h-.75v-.75ZM13.5 19.5h.75v.75h-.75v-.75ZM19.5 13.5h.75v.75h-.75v-.75ZM19.5 19.5h.75v.75h-.75v-.75ZM16.5 16.5h.75v.75h-.75v-.75Z"
                  />
                </svg>
              </div>{" "}
            </div>
            <div className="p-5 lg:col-span-2">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 text-center transition-all">
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">Data</h2>
                  <p className="text-[#35415B]">06-09-2024</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">Invoice No.</h2>
                  <p className="text-[#35415B]">1H-GS-J5</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">
                    Parking Slot
                  </h2>
                  <p className="text-[#35415B]">A01</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">Time Start</h2>
                  <p className="text-[#35415B]">05:00 PM</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">Time End</h2>
                  <p className="text-[#35415B]">05:30 PM</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">
                    CheckIn Time
                  </h2>
                  <p className="text-[#35415B]">10:30 PM</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">
                    CheckOut Time
                  </h2>
                  <p className="text-[#35415B]">11:30 PM</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">Extra Hour</h2>
                  <p className="text-[#35415B]">0.00 hrs</p>
                </div>
                <div className="">
                  <h2 className="text-yellow-500 font-semibold">
                    Extra Time Amount
                  </h2>
                  <p className="text-[#35415B]">Rs. 0.00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingTicket;
