import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../../layout/Header";
import { SidebarContext } from "../../utils/SidebarContext";
import amia from "../../resorces/amia.png";
import { FaPhone } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { useParams } from "react-router-dom";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import DataTable from "../../components/DataTable";
import { Modal, Tooltip } from "antd";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddVehicleForm from "../../layout/AddVehicleForm";

const UserDetails = () => {
  const param = useParams();
  const auth = UseAuth();

  const [userData, setUserData] = useState({
    userDetails: null,
    parkingList: null,
    BookedParking: null,
    walletTransactions: [],
    vehicles: [],
    bookings: [],
  });

  const [amountAdd, setAmountAdd] = useState(0);

  const [activeTab, setActiveTab] = useState("My Bookings");

  const getUserDetail = useCallback(async () => {
    try {
      const response = await axios.get(`admin/customer-profile/${param.id}`, {
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response);
      if (response.status === 200) {
        const data = response?.data?.data;
        setUserData({
          userDetails: data?.userDetails,
          walletTransactions: data?.walletTransactions,
          vehicles: data?.vehicles,
          bookings: data?.bookings,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleDeleteVehicle = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this vehicle?",
      okText: "Yes",
      okType: "danger",
      onOk: async () => {
        try {
          const response = await axios.delete(
            `admin/user-vehicle-details/${id}`,
            {
              headers: {
                Authorization: auth.token,
              },
            }
          );
          if (response.status === 200) {
            Modal.success({
              style: {
                top: 20,
                left: 20,
              },
              title: "Vehicle deleted successfully",
            });
            getUserDetail();
          }
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const [expanded, setExpanded] = useContext(SidebarContext);

  const ParkingList = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Date",
      dataIndex: "date",
    },

    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const walletTransactions = [
    {
      title: "ID",
      render: (_, elem, index) => index + 1,
    },

    {
      title: "PaymentMethod",
      dataIndex: "paymentMethod",
    },

    {
      title: "Txn Id",
      dataIndex: "txnId",
    },
    {
      title: "Date",
      dataIndex: "updatedAt",
      render: (_, elem) => new Date(elem.updatedAt).toLocaleDateString(),
    },
    {
      title: "Note",
      dataIndex: "comment",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
    },
  ];
  const myBookings = [
    {
      title: "ID",
      render: (_, elem, index) => index + 1,
    },
    {
      title: "Date & Time",
      dataIndex: "updatedAt",
      render: (_, elem) => new Date(elem.updatedAt).toLocaleDateString(),
    },

    {
      title: "Duration",
      dataIndex: "duration",
    },

    {
      title: "Slot",
      dataIndex: "slot",
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (elem) => {
        return Number(elem).toFixed(2);
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (_, elem) => new Date(elem.createdAt).toLocaleDateString(),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, elem) => (
        <div className="status">
          <div
            className={`${
              elem.status === "Cancelled"
                ? "text-white bg-red-500"
                : elem.status === "Completed"
                ? "text-white bg-green-500"
                : " text-white bg-orange-300 "
            } text-center rounded-md tracking-wider  
          text-sm font-semibold py-1 px-2`}
          >
            {elem.status}
          </div>
        </div>
      ),
    },
  ];
  // const BookedParking = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //   },

  //   {
  //     title: "Duration",
  //     dataIndex: "duration",
  //   },

  //   {
  //     title: "Slot",
  //     dataIndex: "slot",
  //   },
  //   {
  //     title: "Amount",
  //     dataIndex: "amount",
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "createdAt",
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //   },
  // ];
  const vehicleList = [
    {
      title: "Car Number",
      dataIndex: "licenseNumber",
    },
    {
      title: "Brand",
      dataIndex: "brandName",
    },

    {
      title: "Modal",
      dataIndex: "modelName",
    },

    {
      title: "Action",
      dataIndex: "isActive",
      render: (_, elem) => {
        return (
          <div className="flex gap-3">
            {/* <Tooltip title="Edit">
              <FaPenToSquare
                onClick={(e) => showVehicalModal(e._id)}
                size={24}
                color="#E8A219"
              />
            </Tooltip> */}
            <Tooltip title="Delete">
              <RiDeleteBin6Line
                onClick={() => handleDeleteVehicle(elem?._id)}
                size={24}
                color="#E8A219"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getUserDetail();
  }, []);

  const tabData = [
    // {
    //   title: "Parking List",
    //   column: ParkingList,
    //   dataSource: userData.parkingList

    //   // column:
    // },
    // {
    //   title: "Booked Parking",
    //   column: BookedParking,
    //   dataSource: userData.BookedParking

    // },
    {
      title: "My Bookings",
      column: myBookings,
      dataSource: userData.bookings,
    },
    {
      title: "Vehicle List",
      column: vehicleList,
      dataSource: userData.vehicles,
    },
    {
      title: "Wallet Transation",
      column: walletTransactions,
      dataSource: userData.walletTransactions,
    },
  ];

  // -----------------------------------get vehicle data api --------------------

  const getVehicleData = async (data) => {
    try {
      const res = await axios.post(
        "admin/user-vehicle-details",
        {
          userId: param.id,
          brandId: data?.brand,
          modelId: data?.model,
          licenseNumber: data?.licenseNumber,
          isActive: true,
          type: data?.type,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      if (res.status === 200) {
        handleOkvehicle();
      }
    } catch (error) {
      console.error(error);
      handleCancelVehicle();
    }
  };

  // -----------------------------get single vehicle data api ------------------------------------

  const getSingleVehicleData = async (id) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };

  // ------------------------modal for wallet -----------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    // setId(id)
    setIsModalOpen(true);
    // getUpdateDataApi(id)
  };
  const handleOk = async () => {
    // addFacility()
    try {
      const res = await axios.post(
        "admin/add-amount-to-wallet",
        {
          userId: param.id,
          amount: amountAdd,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log(res);
      if (res.status === 200) {
        alert("Amount Added Successfully");
        getUserDetail();
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log(error);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // ------------------------modal for vehicle ----------------
  const [isModalOpenVehicle, setIsModalOpenVehicle] = useState(false);

  const showVehicalModal = () => {
    setIsModalOpenVehicle(true);
  };
  const handleOkvehicle = () => {
    getUserDetail();
    setIsModalOpenVehicle(false);
  };
  const handleCancelVehicle = () => {
    setIsModalOpenVehicle(false);
  };

  // -------------------------modal for edit vehicle -------------------

  const [editVehicleModal, setEditVehicleModal] = useState(false);

  const showEditVehicleModal = () => {
    setEditVehicleModal(true);
  };
  const handleOkEditVehicle = () => {};

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Users</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <a href="/">Dashboard</a>
                </li>
                <li>
                  <a href="/user">Users</a>
                </li>
                <li>
                  <span className="text-gray-400">{activeTab}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mb-5">
          <div className="grid grid-cols-10 gap-2">
            <div className=" col-span-10 md:col-span-3">
              <div className="card rounded-lg bg-[#a6abb7] p-1">
                <div className="card-head mt-5 m-auto">
                  <img
                    src={userData?.userDetails?.profileImgUrl || amia}
                    alt="amia "
                    height={50}
                    width={50}
                  />
                </div>
                <div className="card-body text-black">
                  <p className="font-medium text-2xl text-center font-[poppins]">
                    {userData?.userDetails?.name || "amia"}
                  </p>
                  <p className="font-medium text-2xl text-center font-[poppins]">
                    Wallet Balance: <span className="font-sans"> ₹ </span>{" "}
                    <span className="text-gray-600">
                      {/* {userData?.userDetails?.wallet.Math.round(2) || "2000"} */}
                      {Math.round(userData?.userDetails?.wallet * 100) / 100}
                    </span>
                  </p>
                  <div
                    onClick={() => showModal()}
                    className="btn bg-[#f07e01] border-0 text-white font-normal dark:hover:text-white hover:text-black text-lg m-auto w-60 my-2"
                  >
                    + Add Wallet Amount
                  </div>
                  {/* *********************************  show modal for wallet add ****************************** */}

                  <Modal
                    open={isModalOpen}
                    onOk={handleOk}
                    okText={"Save"}
                    onCancel={handleCancel}
                  >
                    <h2 className="text-2xl font-semibold">
                      Add Wallet Amount
                    </h2>
                    <form
                      onSubmit={""}
                      className="max-w-md mx-auto mt-10 p-4 border border-gray-200 rounded-lg shadow-md"
                    >
                      <div className="mb-4">
                        <label
                          htmlFor="amount"
                          className="block  text-sm font-medium text-gray-700"
                        >
                          {" "}
                          Add Amount{" "}
                        </label>
                        <input
                          type="amount"
                          value={amountAdd}
                          onChange={(e) => setAmountAdd(e.target.value)}
                          className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </form>
                  </Modal>
                  <div
                    onClick={() => showVehicalModal()}
                    className="btn bg-[#f07e01] border-0 text-white font-normal dark:hover:text-white hover:text-black text-lg m-auto w-60 mb-2"
                  >
                    + Add Vehicle
                  </div>

                  {/* *********************************  show modal for vehicle add ****************************** */}

                  <Modal
                    open={isModalOpenVehicle}
                    onOk={handleOkvehicle}
                    okText={"Save"}
                    onCancel={handleCancelVehicle}
                  >
                    <h2 className="text-2xl font-semibold">Add Vehicle</h2>
                    <AddVehicleForm getVehicleData={getVehicleData} />
                  </Modal>
                </div>
              </div>
              <div className=" my-5">
                <div className=" flex items-center text-lg text-[#35415B] my-5">
                  <span className="mx-3">
                    <IoMailSharp />
                  </span>{" "}
                  {userData?.userDetails?.email || "amia@gmail.com"}
                </div>
                <div className=" flex items-center text-lg text-[#35415B] my-5">
                  <span className="mx-3">
                    <FaPhone />
                  </span>{" "}
                  +91 {userData?.userDetails?.mobile || "amia"}
                </div>
                {/* <div className=" flex items-center text-lg text-[#35415B] my-5">
                  <span className="mx-3">
                    <ImLocation2 />
                  </span>{" "}
                  Banglore, India
                </div> */}
              </div>
            </div>
            <div className="col-span-10 md:col-span-7 m-2 flex flex-col gap-4">
              <div className="head bg-[#35415B] flex items-center justify-between text-white p-3">
                {tabData &&
                  tabData.map((e) => (
                    <p
                      onClick={() => setActiveTab(e.title)}
                      className={` cursor-pointer ${
                        activeTab === e.title
                          ? "btn text-white bg-[#f07e01] border-0 dark:hover:text-white hover:text-black"
                          : ""
                      } `}
                    >
                      {e.title}
                    </p>
                  ))}
              </div>
              <div className="overflow-x-auto">
                {tabData &&
                  tabData
                    .filter((e) => e.title === activeTab)
                    .map((e) => (
                      <DataTable columns={e.column} dataSource={e.dataSource} />
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;

// const UserParkingData = () => {
//   const [data, setData] = useState([]);
//   return (
//     <>
//       <DataTable />
//     </>
//   )
// }
