import React, { useCallback, useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../utils/SidebarContext";
import Header from "../../layout/Header";
import { IoCheckmark } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import { Modal } from "antd";

const AdminCommission = () => {
  const auth = UseAuth();
  const [expanded, setExpanded] = useContext(SidebarContext);

  const [commission, setCommission] = useState({
    id: 0,
    type: "",
    value: 0,
  });

  const getCommission = useCallback(async () => {
    try {
      const response = await axios.get("admin/setting/ADMIN COMISSION", {
        headers: {
          Authorization: auth.token,
        },
      });
      // console.log(response?.data?.data);
      const data = response?.data?.data?.value[0];
      setCommission({
        id: response?.data?.data?._id,
        type: data?.type,
        value: data?.value,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCommission();
  }, []);

  const handleCommissionChange = async () => {
    try {
      const resp = await axios.put(
        `admin/setting/${commission.id}`,
        {
          type: "ADMIN COMISSION",
          value: {
            type: "Percentage",
            value: commission.value,
          },
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      if (resp.status === 200) {
        Modal.success({
          content: resp?.data?.msg || "Commission updated successfully",
        });
      }
    } catch (error) {
      Modal.error({
        content: error?.message || "Something went wrong",
      });
      console.error(error);
    }
  };

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll pb-[5rem]  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Admin Commission
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Admin Commission</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}
        <div className="m-auto p-auto w-5/6 border rounded-md relative my-[5rem]">
          <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
            Admin Commission
          </div>
          <div className="m-auto mb-[3rem]">
            {/* <label className="form-control mx-5 mt-[3rem]">
              <div className="label">
                <span className="   font-semibold">App Version </span>
              </div>
              <input
                type="text"
                placeholder="Type here"
                value={2.1}
                className="input input-bordered "
              />
            </label> */}
            <div className="text-2xl  mx-5 mt-[3rem] flex text-black">
              <IoCheckmark size={30} className="text-success" />
              <span className="font-semibold"> Admin Commission</span>
            </div>

            <div className="grid grid-cols-2 gap-4 text-black">
              <label className="form-control mx-5 mt-[3rem]">
                <div className="label">
                  <span className="   font-semibold">Commission Type </span>
                </div>
                <input
                  type="text"
                  placeholder="Percentage"
                  className="input input-bordered bg-white "
                  value={commission.type}
                  // onChange={(e) => setCommission({
                  //   ...commission,
                  //   type: e.target.value
                  // })}
                />
              </label>{" "}
              <label className="form-control mx-5 mt-[3rem]">
                <div className="label">
                  <span className="   font-semibold">Admin Commission </span>
                </div>
                <input
                  type="number"
                  placeholder="Type here"
                  value={commission?.value}
                  onChange={(e) =>
                    setCommission({
                      ...commission,
                      value: e.target.value,
                    })
                  }
                  className="input input-bordered bg-white"
                />
              </label>
            </div>
          </div>
        </div>

        <div className=" flex justify-center">
          <span
            className="me-2 border w-fit py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
            onClick={() => handleCommissionChange()}
          >
            Save
          </span>
          <span
            className="ms-2 border w-fit py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
          >
            Back
          </span>
        </div>
      </div>
    </>
  );
};

export default AdminCommission;
