import React, { useCallback, useContext, useState, useEffect } from "react";
import { SidebarContext } from "../../utils/SidebarContext";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import { Select } from "antd";

const ParkingReport = () => {
  const auth = UseAuth();
  const [expanded, setExpanded] = useContext(SidebarContext);

  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    getAllParkingReport();
  }, []);

  const status = [
    {
      id: 1,
      name: "Active",
      value: true,
    },
    {
      id: 2,
      name: "In-Active",
      value: false,
    },
  ];

  const column = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (_, elem, index) => index + 1,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const getAllParkingReport = async () => {
    try {
      const response = await axios.get(`admin/reports/parking`, {
        headers: {
          Authorization: auth.token,
        },
      });
      if (response?.status) {
        setReportData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getParkingReport = useCallback(async (e) => {
    try {
      const response = await axios.get(`admin/reports/parking?status=${e}`, {
        headers: {
          Authorization: auth.token,
        },
      });
      if (response.status === 200) {
        setReportData(response?.data?.data);
      }
    } catch (error) {
      setReportData([]);
      console.error(error, "error");
    }
  }, []);

  const showTableData = async (e) => {
    console.log(e);
    // await setSelected(e)
    getParkingReport(e);
  };

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Parking Report
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Parking Report</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}
        <div className="m-auto p-auto w-5/6 border rounded-md relative my-[5rem]">
          <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
            Parking Report
          </div>
          <div className="m-auto">
            <label className="form-control mx-5 mt-[3rem] mb-[2rem]">
              <div className="label">
                <span className="label-text font-semibold">Select Status </span>
              </div>
              <div>
                <Select
                  // value={seleted}
                  placeholder="ALL"
                  style={{ width: "7rem" }}
                  options={status.map((elem) => ({
                    label: elem?.name,
                    value: elem?.value,
                  }))}
                  onChange={(e) => showTableData(e)}
                />
              </div>
            </label>
            <div className="p-5 overflow-x-auto">
              <DataTable columns={column} dataSource={reportData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingReport;
