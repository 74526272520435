import React, { useState } from "react";
import logo from ".././resorces/Logo.png";
import { UseAuth } from "../Context/AuthContext";
import { notification } from "antd";

const LoginForm = () => {
  const auth = UseAuth();
  const [email, setEmail] = useState("admin@motofila.com");
  const [password, setPassword] = useState("123456");

  const [error, setError] = useState(auth.isError);

  const handleSubmit = async (event) => {
    setError(auth.isError);
    event.preventDefault();
    if (email == "" && password == "") {
      notification.error({
        message: "Error",
        description: "Please fill all the fields",
      });
    } else {
      auth.loginAction("email", email, password);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-[#35415B]">
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div className="w-full max-w-sm md:max-w-md lg:max-w-lg p-8 space-y-4 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl font-bold text-center">Login</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Email:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              required
              className={`w-full px-4 py-2 border border-gray-300 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                error == "User not found." ? "border-2 border-red-500" : ""
              }  `}
            />
          </div>
          <div>
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e?.target?.value)}
              required
              className={`w-full px-4 py-2 border border-gray-300 bg-gray-200 rounded-md focus:outline-none 
                focus:ring-2 focus:ring-blue-500 ${
                  error == "Invalid mobile or password."
                    ? "border-2 border-red-500"
                    : ""
                } `}
            />
          </div>
          <button
            type="submit"
            disabled={auth?.isLoading}
            className={`w-full px-4 py-2 text-white rounded-md ${
              auth?.isLoading ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-600"
            } focus:outline-none focus:ring-2 focus:ring-blue-500`}
          >
            {auth?.isLoading ? "Loading..." : "Login"}
          </button>
          {error && <div className="mt-2 text-red-500">*{auth?.isError}</div>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
