import { SidebarContext } from "../utils/SidebarContext";
import { useContext, useEffect, useState } from "react";
import logo from "../resorces/Logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdDashboard, MdAnalytics } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiUsers } from "react-icons/hi";
import { ImUserCheck } from "react-icons/im";
import { TbParkingCircle } from "react-icons/tb";
import { BiSolidUserDetail } from "react-icons/bi";
import { LiaTreeSolid } from "react-icons/lia";
import { IoMdSettings } from "react-icons/io";
import { BsBank } from "react-icons/bs";
import { PiUserGearFill } from "react-icons/pi";
import { TfiSettings } from "react-icons/tfi";
import axios from "../axios";
import { RiArrowDropDownLine, RiArrowDropRightLine } from "react-icons/ri";
// import Cookies from "js-cookie";
// import { UseAuth } from "../Context/AuthContext";

const menuItems = [
  {
    name: "Dashboard",
    icon: MdDashboard,
    path: "/dashboard",
    permission: "USER_ACCESS", // No specific permission needed
  },
  {
    name: "Account Management",
    items: [
      {
        name: "Users",
        icon: HiUsers,
        path: "/users",
        permission: "USER_ACCESS",
      },
      {
        name: "Vendors",
        icon: ImUserCheck,
        path: "/vendor",
        permission: "VENDOR_ACCESS",
      },
    ],
  },
  {
    name: "Report",
    icon: MdAnalytics,
    path: "/report",
    permission: "REPORT_ACCESS",
    subItems: [
      {
        name: "User Report",
        path: "/report/userreport",
        permission: "REPORT_ACCESS",
      },
      {
        name: "Booking Report",
        path: "/report/bookingreport",
        permission: "REPORT_ACCESS",
      },
      {
        name: "Parking Report",
        path: "/report/parkingreport",
        permission: "REPORT_ACCESS",
      },
      {
        name: "Transaction Report",
        path: "/report/transactionreport",
        permission: "REPORT_ACCESS",
      },
    ],
  },
  {
    name: "Parking Management",
    items: [
      {
        name: "Parking List",
        icon: TbParkingCircle,
        path: "/parkingList",
        permission: "PARKING_ACCESS",
      },
      {
        name: "Parking Bookings",
        icon: BiSolidUserDetail,
        path: "/parkingBookings",
        permission: "BOOKING_ACCESS",
      },
      {
        name: "Parking Facilities",
        icon: LiaTreeSolid,
        path: "/parkingFacilities",
        permission: "FACILITIES_ACCESS",
      },
    ],
  },
  {
    name: "Vehicle Settings",
    icon: IoMdSettings,
    path: "/vehicleSettings",
    permission: "VEHICLE_SETTINGS_ACCESS",
    subItems: [
      {
        name: "Brands",
        path: "/vehicleSettings/brand",
        permission: "VEHICLE_SETTINGS_ACCESS",
      },
      {
        name: "Modals",
        path: "/vehicleSettings/modal",
        permission: "VEHICLE_SETTINGS_ACCESS",
      },
    ],
  },
  {
    name: "Sub-Admin Management",
    items: [
      {
        name: "Sub Admin",
        icon: ImUserCheck,
        path: "/subadmin",
        permission: "ADMIN_ACCESS",
      },
      {
        name: "Add Sub Admin",
        icon: HiUsers,
        path: "/addSubAdmin",
        permission: "ADMIN_ACCESS",
      },
    ],
  },
  {
    name: "Setting Management",
    items: [
      {
        name: "Payments",
        icon: BsBank,
        path: "/payment/vendorpayout",
        permission: "PAYMENTS_ACCESS",
      },
      {
        name: "Authorities",
        icon: PiUserGearFill,
        path: "/authorities",
        permission: "USER_ACCESS",
      },
      // {
      //   name: "Settings",
      //   icon: TfiSettings,
      //   path: "/globalsetting",
      //   permission: "SETTINGS_ACCESS",
      //   subItems: [
      //     {
      //       name: "Admin Commission",
      //       path: "/globalsetting/adminCommission",
      //       permission: "SETTINGS_ACCESS",
      //     },
      //     {
      //       name: "Privacy Policy",
      //       path: "/globalsetting/privacyPolicy",
      //       permission: "SETTINGS_ACCESS",
      //     },
      //     {
      //       name: "Terms And Conditions",
      //       path: "/globalsetting/termsAndConditions",
      //       permission: "SETTINGS_ACCESS",
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Settings",
    icon: TfiSettings,
    path: "/globalsetting",
    permission: "SETTINGS_ACCESS",
    subItems: [
      {
        name: "Admin Commission",
        path: "/globalsetting/adminCommission",
        permission: "SETTINGS_ACCESS",
      },
      {
        name: "Privacy Policy",
        path: "/globalsetting/privacyPolicy",
        permission: "SETTINGS_ACCESS",
      },
      {
        name: "Terms And Conditions",
        path: "/globalsetting/termsAndConditions",
        permission: "SETTINGS_ACCESS",
      },
    ],
  },
];

// const auth = UseAuth();
// const token = auth.token;

export default function Sidebar() {
  const navigate = useNavigate();

  const [token, setToken] = useState(localStorage.getItem("Token") || "");

  const width = window.innerWidth;
  const pathName = window.location.pathname;
  const [expanded, setExpanded] = useContext(SidebarContext);
  const [isLoading, setIsLoading] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    image: null,
    permission: [],
    userType: "",
  });

  console.log("profileData permisin", profileData?.permission);

  useEffect(() => {
    // console.log("token hai", token);
    setExpanded(true);

    const getProfileDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get("admin/get-profile-details", {
          headers: {
            Authorization: token,
          },
        });
        setIsLoading(false);
        if (response.status === 200) {
          setProfileData({
            name: response?.data?.data?.name,
            email: response?.data?.data?.email,
            image: response?.data?.data?.image,
            permission: response?.data?.data?.permission,
            userType: response?.data?.data?.userType,
          });
          setIsAdmin(response?.data?.data?.userType.toLowerCase() === "admin");
        }
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    };

    getProfileDetails();
  }, [width]);

  const styleSheet = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    borderTopRightRadius: "0.75rem",
    borderBottomRightRadius: "0.75rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    paddingInlineStart: "1.5rem",
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    fontWeight: "500",
    cursor: "pointer",
    transition:
      "color 150ms cubic-bezier(0.4, 0, 0.2, 1), backgroundColor 150ms cubic-bezier(0.4, 0, 0.2, 1), borderColor 150ms cubic-bezier(0.4, 0, 0.2, 1), textDecorationColor 150ms cubic-bezier(0.4, 0, 0.2, 1), fill 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke 150ms cubic-bezier(0.4, 0, 0.2, 1)",
    fontFamily: "poppins",
  };

  const renderMenuItem = (item) =>
    isAdmin ? (
      <>
        {/* {console.log(item, " item")} */}

        {item?.subItems ? (
          <li key={item.name}>
            <span
              key={item.name}
              style={styleSheet}
              onClick={() => navigate(item?.subItems?.[0]?.path)}
              className={`group z-[1] ${expanded ? "lg:me-[4rem]" : "me-3"} ${
                pathName.includes(item?.path)
                  ? "bg-[#1E1E1E] text-[#F07E01]"
                  : "hover:bg-indigo-50 text-white hover:text-gray-600"
              }`}
            >
              {item?.icon && <item.icon size={30} />}
              <span
                className={`overflow-hidden transition-all flex justify-between items-center text-nowrap ${
                  expanded ? "w-52 ml-3" : "w-0"
                }`}
              >
                {item?.name}
                {pathName.includes(item?.path) ? (
                  <RiArrowDropDownLine size={30} />
                ) : (
                  <RiArrowDropRightLine size={30} />
                )}
              </span>
              {!expanded && (
                <div
                  className={`absolute left-full rounded-md px-2 py-1 ml-6 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 bg-white`}
                >
                  {item?.name}
                </div>
              )}
            </span>
          </li>
        ) : (
          <li
            key={item?.name}
            style={styleSheet}
            onClick={() => navigate(item?.path)}
            className={`group z-[1] ${expanded ? "lg:me-[4rem]" : "me-3"} ${
              pathName.includes(item?.path)
                ? "bg-[#1E1E1E] text-[#F07E01]"
                : "hover:bg-indigo-50 text-white hover:text-gray-600"
            }`}
          >
            {item?.icon && <item.icon size={30} />}
            <span
              className={`overflow-hidden transition-all ${
                expanded ? "w-52 ml-3" : "w-0"
              }`}
            >
              {item?.name}
            </span>
            {!expanded && (
              <div
                className={`absolute left-full rounded-md px-2 py-1 ml-6 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 bg-white`}
              >
                {item?.name}
              </div>
            )}
          </li>
        )}
      </>
    ) : (
      <>
        {profileData?.permission?.includes(item?.permission) &&
          (item?.subItems ? (
            <li
              key={item?.name}
              style={styleSheet}
              onClick={() => navigate(item?.subItems?.[0]?.path)}
              className={`group z-[1] ${expanded ? "lg:me-[4rem]" : "me-3"} ${
                pathName.includes(item?.path)
                  ? "bg-[#1E1E1E] text-[#F07E01]"
                  : "hover:bg-indigo-50 text-white hover:text-gray-600"
              }`}
            >
              {item?.icon && <item.icon size={30} />}
              <span
                className={`overflow-hidden transition-all ${
                  expanded ? "w-52 ml-3" : "w-0"
                }`}
              >
                {item?.name}
              </span>
              {!expanded && (
                <div
                  className={`absolute left-full rounded-md px-2 py-1 ml-6 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 bg-white`}
                >
                  {item?.name}
                </div>
              )}
            </li>
          ) : (
            <li
              key={item?.name}
              style={styleSheet}
              onClick={() => navigate(item?.path)}
              className={`group z-[1] ${expanded ? "lg:me-[4rem]" : "me-3"} ${
                pathName.includes(item?.path)
                  ? "bg-[#1E1E1E] text-[#F07E01]"
                  : "hover:bg-indigo-50 text-white hover:text-gray-600"
              }`}
            >
              {item?.icon && <item.icon size={30} />}
              <span
                className={`overflow-hidden transition-all ${
                  expanded ? "w-52 ml-3" : "w-0"
                }`}
              >
                {item?.name}
              </span>
              {!expanded && (
                <div
                  className={`absolute left-full rounded-md px-2 py-1 ml-6 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0 bg-white`}
                >
                  {item?.name}
                </div>
              )}
            </li>
          ))}
      </>
    );

  const renderSubItems = (subItems) =>
    subItems.map((subItem) => (
      <li
        key={subItem.name}
        onClick={() => navigate(subItem.path)}
        className={` list-disc font-[poppins] ${
          pathName === subItem.path ? "text-[#F07E01]" : ""
        }`}
        role="button"
      >
        {subItem?.name}
      </li>
    ));

  return (
    <aside
      className={`h-screen transition-all text-[15px]  ${
        expanded ? "lg:min-w-[17vw]" : "lg:min-w-[4vw]"
      }`}
    >
      <nav className="h-full flex flex-col bg-white shadow-sm mb-5">
        <div
          className={`p-4 pb-2 flex justify-center items-center bg-[#35415B] ${
            expanded ? "pt-2" : ""
          }`}
        >
          <img
            src={logo}
            className={`overflow-hidden transition-all  object-contain ${
              expanded ? "w-20" : "w-0"
            }`}
            onClick={() => navigate("/")}
            role="button"
            alt=""
          />
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className={`ms-3 text-white ${expanded ? "hidden" : "block"}`}
          >
            <RxHamburgerMenu size={30} />
          </button>
        </div>

        <ul
          className={`flex-1  bg-[#35415B] pt-3 h-screeen overflow-y-auto sb-hide pb-3 ${
            expanded ? "overflow-scroll" : ""
          }`}
        >
          {menuItems.map((item) =>
            item.items ? (
              <>
                <div
                  className={`bg-[#555c69] px-3 py-2 font-medium text-white my-7 text-center text-xl text-[poppins] ${
                    expanded ? "block" : "hidden"
                  }`}
                  key={item.name}
                >
                  {item.name}
                </div>
                {item.items.map((subItem) => renderMenuItem(subItem))}
              </>
            ) : item.subItems ? (
              <>
                {renderMenuItem(item)}
                <ul
                  className={`text-white ms-[4rem] list-disc font-[poppins] space-y-2 ${
                    expanded ? "" : "hidden"
                  } ${pathName.includes(item?.path) ? "block" : "hidden"}`}
                >
                  {renderSubItems(item?.subItems)}
                </ul>
              </>
            ) : (
              renderMenuItem(item)
            )
          )}
        </ul>
      </nav>
    </aside>
  );
}
