import { Select } from "antd";
import axios from "../axios";
import React, { useCallback, useEffect, useState } from "react";
import { UseAuth } from "../Context/AuthContext";

const AddVehicleForm = ({ getVehicleData }) => {
  const auth = UseAuth();

  // ********************************State Declaration  *******************************

  const [brand, setBrand] = useState([]);
  const [model, setModel] = useState([]);

  const [selected, setSelected] = useState({
    brand: null,
    model: null,
    licenseNumber: null,
    type: null,
  });

  // ***************Brand List Api Call **************
  useEffect(() => {
    const getBrand = async () => {
      try {
        const res = await axios.get("admin/brand", {
          headers: {
            Authorization: auth.token,
          },
        });
        if (res.status === 200) {
          setBrand(res?.data?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBrand();
  }, []);

  // ************** Model List Api Calll *************
  useEffect(() => {
    const getModel = async () => {
      try {
        const res = await axios.get(
          `admin/models-by-brand?brandId=${selected?.brand}`,
          {
            headers: {
              Authorization: auth.token,
            },
          }
        );
        if (res.status === 200) {
          setModel(res?.data?.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getModel();
  }, [selected.brand]);

  // ********************************Brand List Data *******************************
  const brandList = brand?.map((elem) => ({
    label: elem?.name,
    value: elem?.brandId,
  }));
  // ********************************Model List Data *******************************

  const modelList = model?.map((elem) => ({
    label: elem?.name,
    value: elem?._id,
  }));

  // ******************************** Type Data*******************************

  const typeData = [
    { label: "Car", value: "car" },
    { label: "Bike", value: "Bike" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    getVehicleData(selected);
  };

  return (
    <form
      onSubmit={""}
      className="max-w-md mx-auto mt-10 p-4 border border-gray-200 rounded-lg shadow-md"
    >
      <div className="mb-4">
        <label
          htmlFor="amount"
          className="block  text-sm font-medium text-gray-700"
        >
          {" "}
          Add Brand{" "}
        </label>

        <Select
          className="w-full"
          placeholder="Select Brand"
          options={brandList}
          value={selected.brand}
          onChange={(e) => {
            setSelected({
              ...selected,
              brand: e,
            });
            // console.log(selected.brand)
          }}
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="amount"
          className="block  text-sm font-medium text-gray-700"
        >
          {" "}
          Add Model{" "}
        </label>
        <Select
          className="w-full"
          placeholder="Select Model"
          value={selected.model}
          options={modelList}
          onChange={(e) =>
            setSelected({
              ...selected,
              model: e,
            })
          }
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="amount"
          className="block  text-sm font-medium text-gray-700"
        >
          {" "}
          License Number{" "}
        </label>
        <input
          type="amount"
          value={selected.licenseNumber}
          onChange={(e) =>
            setSelected({
              ...selected,
              licenseNumber: e.target.value,
            })
          }
          className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="amount"
          className="block  text-sm font-medium text-gray-700"
        >
          Type{" "}
        </label>
        <Select
          className="w-full"
          value={selected.type}
          placeholder="Select Type"
          options={typeData}
          onChange={(e) =>
            setSelected({
              ...selected,
              type: e,
            })
          }
        />
      </div>
      <button
        className="p-2 rounded-md text-white bg-blue-400 border border-blue-400"
        onClick={handleSubmit}
      >
        Add Vehicle
      </button>
    </form>
  );
};

export default AddVehicleForm;
