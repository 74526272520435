import React, { useCallback, useContext, useEffect, useState } from "react";

import "../style.css";
import { Button, Modal, Switch, Table, Tooltip } from "antd";
import Header from "../../layout/Header";
import { SidebarContext } from "../../utils/SidebarContext";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import toast from "react-hot-toast";

const ParkingFacilities = () => {
  const [expanded, setExpanded] = useContext(SidebarContext);
  const auth = UseAuth();

  const [facilitiesData, setFacilitiesData] = useState([]);

  const [image, setImage] = useState(null);
  const [facilityName, setFacilityName] = useState("");
  const [imageId, setImageId] = useState(null);

  const [editData, setEditData] = useState({
    facilityName: "",
    image: null,
    id: null,
  });

  const getFacilities = useCallback(async () => {
    try {
      const response = await axios.get("admin/facilities", {
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response);
      if (response.status === 200) {
        setFacilitiesData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const addFacility = async () => {
    try {
      const response = await axios.post(
        "admin/facility",
        {
          name: facilityName,
          image: imageId,
          isActive: true,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setFacilityName("");
        setImage(null);
        getFacilities();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const editFacility = async () => {
    try {
      const response = await axios.put(
        `admin/facility/${editData.id}`,
        {
          name: editData.facilityName,
          image: imageId,
          isActive: true,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setFacilityName("");
        setImage(null);
        getFacilities();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editDataModal, setEditDataModal] = useState(false);

  const showModal = () => {
    // setId(id)
    setIsModalOpen(true);
    // getUpdateDataApi(id)
  };
  const showModalEdit = (id, name, image) => {
    setEditDataModal(true);
    // setFacilityName(name)
    setEditData({
      id: id,
      facilityName: name,
      image: image,
    });
    // setImage(image)
  };

  const handleOk = async () => {
    addFacility();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkEdit = async () => {
    editFacility();
    setEditDataModal(false);
  };

  const handleCancelEdit = () => {
    setEditDataModal(false);
  };
  const handleChangeActive = async (id, name, image, status) => {
    try {
      const response = await axios.put(
        `admin/facility/${id}`,
        {
          name: name,
          image: image,
          isActive: !status,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      // console.log("response", response);
      if (response.status === 200) {
        toast.success("status updated");
        getFacilities();
      }
    } catch (error) {
      console.error("Error while fetching user detail", error);
    }
  };
  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Parking Facility?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await axios.delete(`admin/facility/${id}`, {
            headers: {
              Authorization: auth.token,
            },
          });
          if (response.status === 200) {
            Modal.success({
              title: response.data.msg || "Deleted Successfully",
            });
            getFacilities();
          }
        } catch (error) {
          Modal.error({
            title: error.message || "Error",
          });
          console.log(error);
        }
      },
    });
  };
  useEffect(() => {
    getFacilities();
  }, []);

  const columns = [
    {
      title: "Image",
      dataIndex: "facilityImage",
      key: "facilityImage",
      render: (text, record) => (
        <img
          src={
            record?.facilityImage ||
            "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
          }
          alt="img"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      render: (_, elem) => (
        <Switch
          onChange={() =>
            handleChangeActive(
              elem?._id,
              elem?.name,
              elem?.image,
              elem?.isActive
            )
          }
          checked={elem?.isActive}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, elem) => (
        <>
          <div className="flex gap-3">
            <Tooltip title="Edit">
              <FaPenToSquare
                onClick={(e) =>
                  showModalEdit(elem._id, elem?.name, elem?.image)
                }
                size={24}
                color="#E8A219"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <RiDeleteBin6Line
                onClick={() => handleDelete(elem?._id)}
                size={24}
                color="#E8A219"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const imageUpload = async (e) => {
    e.preventDefault();
    // const file =;
    console.log(e.target.files);
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    console.log(formData);
    try {
      const response = await axios.post("user/upload/img", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response);
      if (response.status === 200) {
        setEditData({
          ...editData,
          image: response.data.image?._id,
        });
        setImageId(response?.data?.data?._id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Parking Facilities
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Parking Facilities</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>
          <div className=" mb-3 flex justify-center items-center">
            <div className="btn bg-[#35415B] border-0 text-white font-normal dark:hover:text-white hover:text-black text-lg mx-3 flex px-5 py-3">
              <MdOutlineFormatListBulleted size={20} />
              <p> Parking Facilities</p>
            </div>
            <div
              onClick={() => showModal()}
              className="btn bg-[#f07e01] border-0 text-white font-normal dark:hover:text-white hover:text-black text-lg mx-3 px-5 py-3"
            >
              + Add Facilities
            </div>
          </div>

          <div className="pb-5">
            <DataTable columns={columns} dataSource={facilitiesData} />
            <Modal
              open={isModalOpen}
              onOk={handleOk}
              okText={"Save"}
              onCancel={handleCancel}
            >
              <h2 className="text-2xl font-semibold">Add Parking Facilities</h2>
              <form
                onSubmit={""}
                className="max-w-md mx-auto mt-10 p-4 border border-gray-200 rounded-lg shadow-md"
              >
                <div className="mb-4">
                  <label
                    htmlFor="brandName"
                    className="block  text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Add Facility{" "}
                  </label>
                  <input
                    type="text"
                    id="facility_name"
                    value={facilityName}
                    onChange={(e) => setFacilityName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="brandName"
                    className="block  text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Add Image{" "}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    value={image}
                    onChange={imageUpload}
                    className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </form>
            </Modal>
            <Modal
              open={editDataModal}
              onOk={handleOkEdit}
              okText={"Update"}
              onCancel={handleCancelEdit}
            >
              <h2 className="text-2xl font-semibold">Add Parking Facilities</h2>
              <form
                onSubmit={""}
                className="max-w-md mx-auto mt-10 p-4 border border-gray-200 rounded-lg shadow-md"
              >
                <div className="mb-4">
                  <label
                    htmlFor="brandName"
                    className="block  text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Add Facility{" "}
                  </label>
                  <input
                    type="text"
                    id="facility_name"
                    value={editData.facilityName}
                    onChange={(e) =>
                      setEditData({
                        ...editData,
                        facilityName: e.target.value,
                      })
                    }
                    className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="brandName"
                    className="block  text-sm font-medium text-gray-700"
                  >
                    {" "}
                    Add Image{" "}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    value={image}
                    onChange={imageUpload}
                    className="mt-1 block w-full px-3 py-2 border bg-white text-black border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingFacilities;
