import axios from "axios";
import Cookies from "js-cookie";

// FETCHING TOKEN FROM COOKIE
export const token = Cookies.get("token");

export const request = async (props) => {
  const response = await axios?.[props?.method](`${props?.url}`, props?.cred, {
    headers: {
      Authorization: ` ${props?.token}`,
    },
    withCredentials: true,
  });
  return response;
};
export const GetRequest = async (props) => {
  const response = await axios.get(props?.url, {
    headers: {
      Authorization: `${props?.token}`,
    },
    withCredentials: true,
  });
  return response;
};

export const GetData = async (props) => {
  const response = await axios.get(`${props.url}`, {
    headers: {
      Authorization: `${props.token}`,
    },
    withCredentials: true,
  });
  return response;
};

export const postRequest = async (props) => {
  const response = await axios.post(`${props.url}`, props.cred, {
    headers: {
      Authorization: `${props.token}`,
    },
    withCredentials: true,
  });
  return response;
};

export const putRequest = async (props) => {
  const response = await axios.put(props?.url, props?.cred, {
    headers: {
      Authorization: ` ${props?.token}`,
    },
    withCredentials: true,
  });
  return response;
};

export const deleteRequest = async (props) => {
  const response = await axios.delete(props?.url, props?.cred, {
    headers: {
      Authorization: ` ${props?.token}`,
    },
    withCredentials: true,
  });
  return response;
};

export const noTokenGetRequest = async (props) => {
  const response = axios.get(props?.url, {
    withCredentials: true,
  });
  return response;
};

export const noTokenpostRequest = async (props) => {
  const response = axios.post(props?.url, {
    withCredentials: true,
  });
  return response;
};

export const noTokenPutRequest = async (props) => {
  const response = await axios.put(props?.url, {
    withCredentials: true,
  });
  return response;
};
