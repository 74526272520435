import axios from "axios";

// console.log(process.env.REACT_APP_BASE_URL);

const instance = axios.create({
  // baseURL: "http://103.160.107.21:8000/v1/",
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "http://192.168.29.62:8000/v1/",
  headers: {
    post: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    get: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
  withCredentials: false,
});

export default instance;
