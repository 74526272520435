import React, { useCallback, useContext, useEffect, useState } from "react";
import Header from "../layout/Header";
import "./style.css";
import { SidebarContext } from "../utils/SidebarContext";
import { Link } from "react-router-dom";
import axios from "../axios";
import { UseAuth } from "../Context/AuthContext";
import DataTable from "../components/DataTable";
import { Switch } from "antd";

const Vendor = () => {
  const auth = UseAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getVendorData();
  }, []);

  const getVendorData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get("admin/vendor", {
        headers: {
          Authorization: auth.token,
        },
      });
      setIsLoading(false);
      if (resp.status === 200) {
        setData(resp.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, []);
  const handleChangeActive = async (id, status) => {
    try {
      const response = await axios.post(
        "admin/block-unblock-user-vendor",
        {
          userId: id,
          status: !status,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log("response", response);
      if (response.status === 200) {
        getVendorData();
      }
    } catch (error) {
      console.error("Error while fetching user detail", error);
    }
  };

  const [expanded, setExpanded] = useContext(SidebarContext);
  const columns = [
    {
      title: "Sl No.",
      dataIndex: "id",
      key: "id",
      // sorter: (a, b) => a.id - b.id,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Image",
      dataIndex: "profileImg",
      render: (_, elem) => (
        <>
          <img
            src={`${
              elem?.profileImgUrl ||
              "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
            }`}
            alt="img"
            height={50}
            width={50}
          />
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      // ...useColumnSearchProps('name')
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    // },
    {
      title: "DOJ",
      dataIndex: "createdAt",
      render: (_, elem) => new Date(elem?.createdAt).toLocaleDateString(),
    },
    {
      title: "Phone",
      dataIndex: "mobile",
    },

    {
      title: "Approval",
      dataIndex: "status",
      render: (_, elem) => (
        // <input
        //   type="checkbox"
        //   className="toggle toggle-success toggle-sm focus:outline-none"
        //   checked={elem?.status}
        //   onChange={() => handleChangeActive(elem?._id, elem?.status)}
        // />
        <Switch
          onChange={() => handleChangeActive(elem?._id, elem?.status)}
          checked={elem?.status}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (elem) => (
        <div className=" text-center px-2 py-1">
          <Link
            className="dropdown-item border-radius-md"
            to={`vendordetail/${elem?._id}`}
          >
            View
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Vendor</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to="/">Dashboard</Link>
                </li>
                <li>
                  <span>Vendor</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>
          {/* <div className=" mb-3 flex justify-between items-center">
            <div className="">
              Show{" "}
              <input
                type="number"
                className="px-3 py-2 border border-black w-14"
                value={10}
              />
              Entries
            </div>
            <div className="btn bg-[#f07e01] border-0 text-white font-normal text-sm hover:text-white ">
              + Add Parking Prices
            </div>

          </div> */}
          <div className="pb-5 overflow-x-auto">
            <DataTable columns={columns} dataSource={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Vendor;
