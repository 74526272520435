import React, { useCallback, useContext, useState } from "react";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../utils/SidebarContext";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import axios from "../../axios.js";
import { UseAuth } from "../../Context/AuthContext";

const Addpages = () => {
  const [expanded, setExpanded] = useContext(SidebarContext);
  const auth = UseAuth();

  // -------------------------------------- State ----------------------------------

  const [addData, setAddData] = useState({
    title: "",
    slug: "",
    description: "",
  });
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");

  // ------------------------------------------ State Data Change ----------------------

  const handleChange = (e) => {
    setAddData({
      ...addData,
      [e.target.name]: e.target.value,
    });
  };

  // ------------------------------------- Add Pages Api ---------------------------------

  const AddPageApi = async () => {
    // console.log("add daata ", title, slug, content);
    try {
      const response = await axios.post(
        "admin/page",
        {
          name: title,
          slug: slug,
          content: content,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );

      console.log(response);
    } catch (error) {
      console.error(error, "error while adding pages");
    }
  };

  // --------------------------------------- Editor Options -----------------------------

  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana",
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    "Kurti Dev 010",
    "Noto Sans Devanagari",
    ...defaultFonts,
  ].sort();

  const options = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize"],
      ["paragraphStyle", "blockquote"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["align", "list", "lineHeight"],
      ["outdent", "indent"],

      ["table", "horizontalRule", "link", "image", "video"],
      // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
      // ['imageGallery'], // You must add the "imageGalleryUrl".
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      ["removeFormat"],

      // ['save', 'template'],
      // '/', Line break
    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
    defaultTag: "div",
    minHeight: "300px",
    defaultStyle: 'font-family: "Noto Sans Devanagari", sans-serif;',
    showPathLabel: false,
    font: sortedFontOptions,
  };

  return (
    <div className="addpages">
      <Header />

      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll pb-[5rem]  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">Add Pages</div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Add Pagess</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}
        <div className="m-auto p-auto w-5/6 h-auto border rounded-md relative my-[5rem] min-h-[50vh]">
          <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
            Add Pages
          </div>
          <div className="p-10 ">
            <form>
              <div>
                <label className="text-[#35415B] font-semibold text-sm">
                  Page Name
                </label>
                <input
                  value={title}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  className="w-full border rounded-md p-2 mt-2"
                />
              </div>
              <div>
                <label className="text-[#35415B] font-semibold text-sm">
                  Slug
                </label>
                <input
                  type="text"
                  value={slug}
                  name="slug"
                  onChange={(e) => setSlug(e.target.value)}
                  className="w-full border rounded-md p-2 mt-2"
                />
              </div>
              <div className="mt-5">
                <label className="text-[#35415B] font-semibold text-sm">
                  Content
                </label>
                <SunEditor
                  setContents={content}
                  setOptions={options}
                  onChange={(e) => setContent(e)}
                />
              </div>
            </form>
          </div>
        </div>

        <div className=" flex justify-center">
          <span
            onClick={AddPageApi}
            className="me-2 border w-fit  py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
          >
            Save
          </span>
          <span
            className="ms-2 border w-fit  py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
          >
            Back
          </span>
        </div>
      </div>
    </div>
  );
};

export default Addpages;
