import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import Header from "../../layout/Header";
import { GetData, GetRequest, postRequest } from "../../utils/helper/Index";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import { useNavigate, useNavigation } from "react-router-dom";

export default function AddSubAdmin() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    profilePhoto: null,
    permissions: [],
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [permissionError, setPermissionError] = useState("");
  const auth = UseAuth();
  const navigate = useNavigate();
  const token = auth.token;
  const permissionsList = [
    "USER_ACCESS",
    "VENDOR_ACCESS",
    "REPORT_ACCESS",
    "PARKING_ACCESS",
    "BOOKING_ACCESS",
    "FACILITIES_ACCESS",
    "VEHICLE_SETTINGS_ACCESS",
    "PAYMENTS_ACCESS",
    "SETTINGS_ACCESS",
  ];

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        permissions: checked
          ? [...prevData.permissions, value]
          : prevData.permissions.filter((perm) => perm !== value),
      }));
    } else if (type === "file") {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    // Validate phone number length
    if (name === "phone") {
      if (value.length !== 10) {
        setErrorMessage("Phone number must be exactly 10 digits.");
      } else {
        setErrorMessage("");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    if (formData.phone.length !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      isValid = false;
    }

    if (formData.permissions.length === 0) {
      setPermissionError("At least one permission must be selected.");
      isValid = false;
    }

    if (isValid) {
      try {
        const res = await axios.post(
          "admin/add-sub-admin",
          {
            name: formData.name,
            mobile: formData.phone,
            email: formData.email,
            password: formData.password,
            permission: formData.permissions,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log(res?.data?.msg);
        toast.success(res?.data?.msg);
        navigate("/subadmin");
        setFormData({
          name: "",
          phone: "",
          email: "",
          password: "",
          profilePhoto: null,
          permissions: [],
        });
        setErrorMessage("");
        setPermissionError("");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Header />
      <div className="flex justify-center items-center min-h-screen bg-gray-100 p-4">
        <div className="w-full max-w-2xl bg-white p-8 rounded-lg shadow-lg overflow-y-auto max-h-screen">
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
            Add Sub Admin
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="Enter name"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  required
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {errorMessage && (
                  <p className="text-red-500 text-xs italic">{errorMessage}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  required
                  onChange={handleChange}
                  placeholder="Enter email"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  placeholder="Enter password"
                  className="shadow appearance-none border bg-white rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="profilePhoto"
              >
                Profile Photo
              </label>
              <input
                type="file"
                id="profilePhoto"
                name="profilePhoto"
                onChange={handleChange}
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Permissions
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {permissionsList.map((permission) => (
                  <label key={permission} className="flex items-center">
                    <input
                      type="checkbox"
                      name="permissions"
                      value={permission}
                      checked={formData.permissions.includes(permission)}
                      onChange={handleChange}
                      className="mr-2 leading-tight"
                    />
                    <span className="text-sm">
                      {permission.replace(/_/g, " ")}
                    </span>
                  </label>
                ))}
              </div>
              {permissionError && (
                <p className="text-red-500 text-xs italic">{permissionError}</p>
              )}
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="m-auto border w-fit px-2 py-1 rounded-md bg-[#35415B] flex justify-between text-white"
              >
                Add Sub Admin
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
