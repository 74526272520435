import React, { useCallback, useContext, useEffect, useState } from "react";

import "../style.css";
import { Button, Table } from "antd";
import Header from "../../layout/Header";
import { SidebarContext } from "../../utils/SidebarContext";
import { MdOutlineFormatListBulleted } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { UseAuth } from "../../Context/AuthContext";
import DataTable from "../../components/DataTable";

const ParkingBookings = () => {
  const auth = UseAuth();

  const [expanded, setExpanded] = useContext(SidebarContext);

  const [parkingBookingData, setParkingBookingData] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  function reduceFloatToTwoDecimalPlaces(input) {
    const number = parseFloat(input);
    if (isNaN(number)) {
      throw new Error("Input must be a number");
    }
    return number.toFixed(2);
  }

  const columns = [
    {
      title: "Serial No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Parking Name",
      dataIndex: "parkingName",
      key: "parkingName",
    },

    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, elem) =>
        new Date(elem?.createdAt).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        }),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Slot",
      dataIndex: "slot",
      key: "slot",
    },
    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (_, elem) => (
        <div className="d-flex justify-content-center align-items-center">
          <span className="mr-2">₹</span>
          <span>{reduceFloatToTwoDecimalPlaces(elem?.total)}</span>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, elem) => (
        <div className="status">
          {elem?.status && (
            <div
              className={`${
                elem.status === "Cancelled"
                  ? "text-white bg-red-500"
                  : elem.status === "Completed"
                  ? "text-white bg-green-500"
                  : " text-white bg-orange-300 "
              } text-center rounded-md tracking-wider  
          text-sm font-semibold py-1 px-2`}
            >
              {elem.status}
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getParkignDataApi();
  }, []);

  const getParkignDataApi = useCallback(async () => {
    try {
      const response = await axios.get("admin/booking", {
        headers: {
          Authorization: auth.token,
        },
      });
      setParkingBookingData(response.data.data);
    } catch (error) {
      console.error(error, "error");
    }
  }, []);

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Parking Bookings
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <span>Parking Bookings</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div style={{ marginBottom: 16 }}>
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>
          <div className=" mb-3 flex justify-center items-center"></div>

          <div className="pb-5 overflow-auto">
            <DataTable
              // rowSelection={rowSelection}
              columns={columns}
              dataSource={parkingBookingData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ParkingBookings;
