import React, { useCallback, useContext, useEffect, useState } from "react";

import "../../style.css";
import { Modal, Switch, Tooltip } from "antd";
import Header from "../../../layout/Header";
import { SidebarContext } from "../../../utils/SidebarContext";
import { Link } from "react-router-dom";
import axios from "../../../axios";
import { UseAuth } from "../../../Context/AuthContext";
import useColumnSearchProps from "../../../hooks/useColumnSearchProps";
import DataTable from "../../../components/DataTable";
import { FaPenToSquare } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import toast from "react-hot-toast";

const Brands = () => {
  const auth = UseAuth();
  // console.log(process.env.REACT_APP_BASE_URL, 'react base');

  const [id, setId] = useState("");

  const baseurl = process.env.REACT_APP_BASE_URL;

  const [allBrnadsData, setAllBrandsData] = useState([]);

  const getAllBrands = async () => {
    try {
      const resp = await axios.get("admin/brand", {
        headers: {
          Authorization: auth.token,
        },
      });
      setAllBrandsData(resp.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeActive = useCallback(async (id, status) => {
    try {
      const response = await axios.put(
        `admin/brand/${id}`,
        {
          isActive: !status,
        },
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      if (response.status === 200) {
        toast.success("status updated");
        getAllBrands();

        console.log(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const [expanded, setExpanded] = useContext(SidebarContext);
  const columns = [
    {
      title: "Brand Id",
      key: "brandId",
      dataIndex: "brandId",
      render: (_, elem) => <span>{elem?.brandId}</span>,
    },
    {
      title: "Brand Name",
      key: "name",
      dataIndex: "name",
      ...useColumnSearchProps("name"),
      render: (_, elem) => <span>{elem?.name}</span>,
    },

    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (_, elem) => (
        // <span>{elem?.isActive ? "Active" : "Inactive"}</span>
        // <input
        //   type="checkbox"
        //   className="toggle toggle-success toggle-sm focus:outline-none"
        //   checked={elem?.isActive}
        //   onChange={() => handleChangeActive(elem?._id, elem?.isActive)}

        // />
        <Switch
          onChange={() => handleChangeActive(elem?._id, elem?.isActive)}
          checked={elem?.isActive}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_, elem) => (
        <>
          <div className="flex gap-3">
            <Tooltip title="Edit">
              <FaPenToSquare
                onClick={(e) => showModal(elem?._id, elem?.name)}
                size={24}
                color="#E8A219"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <RiDeleteBin6Line
                onClick={(e) => handleDelete(e, elem?._id)}
                size={24}
                color="#E8A219"
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const data = [...allBrnadsData];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = (id, name) => {
    console.log(id);
    setId(id);
    setUpdateFields((prev) => {
      return { ...prev, name: name };
    });
    setIsModalOpen(true);
    getUpdateDataApi(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    updateBrandData();
    getAllBrands();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBrandNameChange = (e) => {
    setUpdateFields({
      name: e.target.value,
    });
  };

  const [updateFields, setUpdateFields] = useState({
    name: "",
    isActive: false,
  });

  useEffect(() => {
    getAllBrands();
  }, []);

  const getUpdateDataApi = async (id) => {
    try {
      const response = await axios.get(baseurl + `/brand/${id}`, {
        headers: {
          Authorization: auth.token,
        },
      });
      console.log(response.data.data);
      setUpdateFields({
        name: response?.data?.data?.name,
        brandId: response?.data?.data?.brandId,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (e, id) => {
    e.preventDefault();

    Modal.confirm({
      title: "Are you sure you want to delete this brand?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await axios.delete(`admin/brand/${id}`, {
            headers: {
              Authorization: auth.token,
            },
          });
          if (response.status === 200) {
            Modal.success({
              title: response.data.msg || "Deleted Successfully",
            });
            getAllBrands();
          }
        } catch (error) {
          Modal.error({
            title: error.message || "Error",
          });
          console.log(error);
        }
      },
    });
  };

  const updateBrandData = async () => {
    try {
      const response = await axios.put(
        baseurl + `/brand/${updateFields._id}`,
        updateFields,
        {
          headers: {
            Authorization: auth.token,
          },
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll  ${
          expanded ? "" : " md:ms-[3rem] "
        } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Vehicle Settings
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={"/"}>Dashboard</Link>
                </li>
                <li>
                  <Link to={"/vehicleSettings/brand"}>Vehicle Settings</Link>
                </li>
                <li>
                  <span>Brands</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <form
            onSubmit={""}
            className="max-w-md mx-auto mt-10 p-4 border border-gray-200 rounded-lg shadow-md"
          >
            <div className="mb-4">
              <label
                htmlFor="brandName"
                className="block  text-sm font-medium text-gray-700"
              >
                Edit Brand Name{" "}
              </label>
              <input
                type="text"
                id="brandName"
                value={updateFields.name}
                onChange={handleBrandNameChange}
                className="mt-1 block w-full px-3 py-2 border  bg-white border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </form>
        </Modal>

        <div className="mb-5">
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <span
              style={{
                marginLeft: 8,
              }}
            ></span>
          </div>

          <div className="pb-5">
            <DataTable columns={columns} dataSource={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
