import React, { useCallback, useContext, useEffect, useState } from 'react'
import Header from '../../layout/Header'
import { Link, useParams } from 'react-router-dom'
import { SidebarContext } from '../../utils/SidebarContext';

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from '../../axios.js';
import { UseAuth } from '../../Context/AuthContext';
import { Alert, Modal } from 'antd';

const TermsAndConditions = () => {
  const param = useParams()
  const [expanded, setExpanded] = useContext(SidebarContext);
  const auth = UseAuth()


  // -------------------------------------- State ----------------------------------

  const [addData, setAddData] = useState({
    id: '',
    title: '',
    slug: '',
    description: '',
  })
  const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [slug, setSlug] = useState('')
  const [content, setContent] = useState('')


  // ------------------------------------------ State Data Change ----------------------

  const handleChange = (e) => {
    setAddData({
      ...addData,
      [e.target.name]: e.target.value
    })
  }



  // ----------------------------------------- get all pages ---------------------------

  const getAllPage = useCallback(async () => {
    try {
      const resp = await axios.get(`admin/page/terms-and-condition`, {
        headers: {
          'Authorization': auth.token
        }
      })
      if (resp.status === 200) {

        setName(resp?.data?.data?.name)
        setSlug(resp?.data?.data?.slug)
        setContent(resp?.data?.data?.content)
        setId(resp?.data?.data?._id)
      }
    } catch (error) {

      console.error(error);
    }
  }, [])

  // ------------------------------------- Update Pages Api ---------------------------------

  const updatePageApi = async () => {
    console.log(id, 'id');
    try {
      const resp = await axios.put(`admin/page/${id}`, {
        name: name,
        slug: slug,
        content: content
      }, {
        headers: {
          'Authorization': auth.token
        }
      })
      if (resp.status === 200) {
        window.scrollTo(0, 0)
        Modal.success({
          content: resp.data.msg || 'Page Updated Successfully',
        })
        setAddData({
          id: resp.data.data._id,
          title: resp.data.data.name,
          slug: resp.data.data.slug,
          description: resp.data.data.content
        })
        setId(resp?.data?.data?._id)
      }
    }
    catch (error) {
      Modal.error({
        content: error.message || 'Somthing went wrong',
      })
      console.error(error);
    }
  }


  // --------------------------------------- Editor Options -----------------------------

  const defaultFonts = [
    "Arial",
    "Comic Sans MS",
    "Courier New",
    "Impact",
    "Georgia",
    "Tahoma",
    "Trebuchet MS",
    "Verdana"
  ];
  const sortedFontOptions = [
    "Logical",
    "Salesforce Sans",
    "Garamond",
    "Sans-Serif",
    "Serif",
    "Times New Roman",
    "Helvetica",
    "Kurti Dev 010",
    "Noto Sans Devanagari",
    ...defaultFonts
  ].sort();

  const options = {
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize"],
      ['paragraphStyle', 'blockquote'],
      [
        "bold",
        "underline",
        "italic",
        "strike",
        "subscript",
        "superscript"
      ],
      ["fontColor", "hiliteColor"],
      ["align", "list", "lineHeight"],
      ["outdent", "indent"],

      ["table", "horizontalRule", "link", "image", "video"],
      // ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
      // ['imageGallery'], // You must add the "imageGalleryUrl".
      ["fullScreen", "showBlocks", "codeView"],
      ["preview", "print"],
      ["removeFormat"]

      // ['save', 'template'],
      // '/', Line break
    ], // Or Array of button list, eg. [['font', 'align'], ['image']]
    defaultTag: "div",
    minHeight: "300px",
    defaultStyle: 'font-family: "Noto Sans Devanagari", sans-serif;',
    showPathLabel: false,
    font: sortedFontOptions
  };


  useEffect(() => {
    getAllPage()
  }, [])


  return (
    <div className="addpages">
      <Header />

      <div
        className={`p-6 font-[poppins] h-screen overflow-scroll pb-[5rem]  ${expanded ? "" : " md:ms-[3rem] "
          } `}
      >
        <div className="flex justify-between items-center mb-5">
          <div className="text-xl font-medium text-[#35415B]">
            Terms And Conditions
          </div>
          <div className="">
            <div className="text-sm breadcrumbs text-[#35415B] font-semibold">
              <ul>
                <li>
                  <Link to={'/'} >Dashboard</Link>
                </li>
                <li>
                  <span>Terms And Conditions</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* ---------------------------------------------------------Form--------------------------------------------------------------- */}
        <div className="m-auto p-auto w-5/6 h-auto border rounded-md relative my-[5rem] min-h-[50vh]">
          <div className="bg-[#35415B] rounded-md left-12 top-[-1rem] py-1 px-2 absolute text-white">
            Terms And Conditions
          </div>
          <div className='p-10 '>

            <form>
              <div>
                <label className="text-[#35415B] font-semibold text-sm">Page Name</label>
                <input value={name} name='title' onChange={(e) => setName(e.target.value)} type="text" className="w-full border rounded-md p-2 mt-2" />
              </div>

              <div className="mt-5">
                <label className="text-[#35415B] font-semibold text-sm">Content</label>
                <SunEditor
                  setContents={content}
                  setOptions={options}
                  onChange={(e) => (
                    setContent(e)
                  )}
                />
              </div>
            </form>
          </div>
        </div>

        <div className=" flex justify-center">
          <span
            onClick={() => updatePageApi(id)}
            className="me-2 border w-fit  py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
          >
            Save
          </span>
          <span
            className="ms-2 border w-fit  py-1 rounded-md bg-[#35415B] text-white px-7"
            role="button"
          >
            Back
          </span>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions